import { ApiTask } from 'src/types';
import {
  fetchUpdateTaskById,
  useAppDispatch,
  fetchConversationById,
} from 'src/store';
import { useThreads, useSession } from 'src/hooks';

export const useReadMessage = () => {
  const dispatch = useAppDispatch();
  const { currentThread } = useThreads();
  const { appUser } = useSession();

  const readMessage = async (taskId: string) => {
    await dispatch(
      fetchUpdateTaskById(
        {
          userId: appUser.user_id,
          task: {
            task_id: taskId,
            requires_attention: false,
          } as ApiTask
        })
    );

    // TODO(olha): after fixing DPU event should be removed
    dispatch(fetchConversationById({
      userId: appUser.user_id,
      conversationId: currentThread?.conversation_id || ''
    }));
  };

  return { readMessage };
};
