import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatModeSidePanel } from 'src/types';
import { RootState } from '../index';

interface InterfaceControl {
  chatModeSidePanel: ChatModeSidePanel;
  isNavigationBarExpanded: boolean;
  isUserSettingsBarExpanded: boolean;
  isSettingsPanelExpanded: boolean;
  isTasksListExpanded: boolean;
  chatsPageSize?: number;
}

const initialState: InterfaceControl = {
  chatModeSidePanel: ChatModeSidePanel.NONE,
  isSettingsPanelExpanded: false,
  isNavigationBarExpanded: false,
  isUserSettingsBarExpanded: false,
  isTasksListExpanded: false,
};

export const interfaceControlSlice = createSlice({
  name: 'interfaceControl',
  initialState,
  reducers: {
    setChatsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.chatsPageSize = payload;
    },
    setChatModeSidePanel: (
      state,
      { payload }: PayloadAction<ChatModeSidePanel>,
    ) => {
      state.chatModeSidePanel = payload;
    },
    setIsNavigationBarExpanded: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isNavigationBarExpanded = payload;
    },
    setIsUserSettingsBarExpanded: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isUserSettingsBarExpanded = payload;
    },
    setIsSettingsPanelExpanded: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSettingsPanelExpanded = payload;
    },
    setIsTasksListExpanded: (state, { payload }: PayloadAction<boolean>) => {
      state.isTasksListExpanded = payload;
    },
  },
});

export const interfaceControlState = (state: RootState) =>
  state.interfaceControl as InterfaceControl;

export const {
  setChatModeSidePanel,
  setIsNavigationBarExpanded,
  setIsUserSettingsBarExpanded,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
  setChatsPageSize,
} = interfaceControlSlice.actions;

export default interfaceControlSlice.reducer;
