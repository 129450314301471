import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { tasksApi } from '../services';
import {
  fetchConversationById,
  fetchFilteredConversationTasks,
  fetchNextPageTasks,
  fetchNextTopConversations,
  fetchTaskById,
  fetchUpdateConversationById,
  fetchUpdateTaskById,
} from '../thunks';
import {
  ApiMinimumTask,
  ApiTask,
  ApiTaskSelectable,
  Conversation,
  ConversationRole,
  FeedbackType,
  Message,
  MessageChunk,
  MessageFooter,
  MessageType,
  OperationType,
  TasksByPageResponse,
  TaskState,
} from 'src/types';
import { RootState } from '../index';
import { DEFAULT_CHAT_ID } from 'src/constants';
import {
  convertToMinimumApiTask,
  getStreamableAvatarChunks,
  mergeArraysWithSortUniqueElementsByKey,
  recursiveDeepMerge,
  recursiveUpdatePayload,
  removeReferencesInBrackets,
  taskGTMEvent,
} from 'src/utils';
import { Feedback } from 'src/types/models/Feedback';
import { PURGE } from 'reduxjs-toolkit-persist';

dayjs.extend(utc);

export type StatusRegistry = {
  ignoreMessages?: boolean;
  isSubmitHappened?: boolean;
};

export type ThreadsStatusRegistry = Record<string, StatusRegistry>;

export interface ThreadsStatusRegistryPayload {
  threadId: string;
  statusRegistry: StatusRegistry;
}

export interface UserChatsState {
  conversations: Conversation[];
  tasks: Partial<ApiTaskSelectable>[];
  pageToken?: string;
  cameFrom?: string;
  shouldAnimate?: [string, string];
  threadsStatusRegistry: ThreadsStatusRegistry;
  isFetchingConversation: boolean;
  // TODO(olha): avatarSpeech is deprecated
  avatarSpeech?: string;
}

const initialState = {
  conversations: [] as Conversation[],
  tasks: [] as Partial<ApiTaskSelectable>[],
  cameFrom: undefined,
  avatarSpeech: '',
  ignoreMessages: false,
  isSubmitHappened: false,
  threadsStatusRegistry: {},
  isFetchingConversation: false,
} as UserChatsState;

interface UserTaskFeedback {
  task_id: string;
  feedback_type: FeedbackType;
}

interface UserConversationMessageFeedbackType {
  conversation_id: string;
  message_id: string;
  feedback_type: FeedbackType;
}

interface UserConversationMessageFeedback {
  taskId?: string;
  conversation_id: string;
  message_id: string;
  feedback?: Feedback;
}

interface UserTaskChatMessageFeedback {
  task_id: string;
  message_id: string;
  user_id: string;
  feedback_type: FeedbackType;
}

export const userChatsSlice = createSlice({
  name: 'userChats',
  initialState,
  reducers: {
    /**
     * Auxilary flags to manage conversation states.
     */
    setAvatarSpeech: (state, action: PayloadAction<string>) => {
      state.avatarSpeech = action.payload;
    },
    setThreadStatus: (
      state,
      action: PayloadAction<ThreadsStatusRegistryPayload>,
    ) => {
      state.threadsStatusRegistry = {
        ...state.threadsStatusRegistry,
        [action.payload.threadId]: {
          ...(state.threadsStatusRegistry[action.payload.threadId] || {}),
          ...action.payload.statusRegistry,
        },
      };
    },
    updateUserChatsState: (
      state,
      action: PayloadAction<Partial<UserChatsState>>,
    ) => {
      const updatedState = { ...state, ...action.payload };
      state = updatedState;
    },
    setShouldAnimate: (
      state,
      action: PayloadAction<[string, string] | undefined>,
    ) => {
      state.shouldAnimate = action.payload || ['', ''];
    },
    archiveConversationTask: (
      state,
      action: PayloadAction<{ task_id: string; conversation_id: string }>,
    ) => {
      const { task_id, conversation_id } = action.payload;

      const foundConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation_id === conversation.conversation_id,
      );

      if (foundConversation) {
        const foundConversationTasks = foundConversation?.tasks?.map(
          (task: ApiMinimumTask) => {
            if (task.task_id === task_id) {
              return {
                ...task,
                state: TaskState.ARCHIVED,
              };
            }
            return task;
          },
        );

        if (foundConversationTasks) {
          const updatedConversations = state.conversations.map(
            (conversation: Conversation) => {
              if (conversation.conversation_id === conversation_id) {
                return {
                  ...conversation,
                  tasks: foundConversationTasks,
                };
              }
              return conversation;
            },
          );

          state.conversations = updatedConversations;
        }
      }
    },
    /**
     * Newest hierarchical conversational routines for userTasks.conversations
     */
    addConversationChat: (state, action: PayloadAction<Conversation>) => {
      const { conversation_id } = action.payload;

      // filter out default conversation in case it exists
      if (conversation_id !== DEFAULT_CHAT_ID) {
        state.conversations.push(action.payload);
      } else {
        const foundDefaultConversation = state.conversations.find(
          (conversation: Conversation) =>
            conversation.conversation_id === DEFAULT_CHAT_ID,
        );

        if (!foundDefaultConversation) {
          state.conversations.push(action.payload);
        }
      }
    },
    replaceDefaultChat: (state, action: PayloadAction<Conversation>) => {
      const newConversationId = action.payload.conversation_id;
      const hasCurrentConversation = state.conversations.find(
        (item) => item.conversation_id === newConversationId,
      );

      // TODO(olha): temporary workaround for fixing duplicates on the task list
      if (hasCurrentConversation) {
        return;
      }

      const foundDefaultConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === DEFAULT_CHAT_ID,
      );

      let updatedConversations: Conversation[] = [];

      if (!foundDefaultConversation) {
        updatedConversations = [action.payload, ...state.conversations];
      } else {
        updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (conversation.conversation_id === DEFAULT_CHAT_ID) {
              return action.payload;
            }
            return conversation;
          },
        );
      }

      state.conversations = updatedConversations;
      state.shouldAnimate = [
        'conversation',
        action.payload?.conversation_id || '',
      ];

      if (newConversationId) {
        state.threadsStatusRegistry = {
          ...state.threadsStatusRegistry,
          [DEFAULT_CHAT_ID]: {
            ignoreMessages: false,
            isSubmitHappened: false,
          },
          [newConversationId]: {
            isSubmitHappened: true,
          },
        };
      }
    },
    clearConversationChatMessages: (state, action: PayloadAction<string>) => {
      const conversation_id = action.payload;
      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation.conversation_id === conversation_id) {
            return {
              ...conversation,
              messages: [],
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
    },
    addMinimumTaskToConversationChat: (
      state,
      action: PayloadAction<ApiMinimumTask>,
    ) => {
      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (
            action.payload.parent_conversation_id ===
            conversation.conversation_id
          ) {
            return {
              ...conversation,
              tasks: [...(conversation.tasks || []), action.payload],
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
    },
    addTaskToConversationChat: (
      state,
      action: PayloadAction<Partial<ApiTaskSelectable>>,
    ) => {
      const minimizedTask = convertToMinimumApiTask(action.payload);

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (
            action.payload.parent_conversation_id ===
            conversation.conversation_id
          ) {
            return {
              ...conversation,
              tasks: [...(conversation.tasks || []), minimizedTask],
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
    },
    // No operation_type, creates a new non-streaming chit-chat message
    addMessageToConversationChat: (state, action: PayloadAction<Message>) => {
      // route messages without conversation_id to default new window
      const { content, role } = action.payload;

      let conversationId = action.payload.conversation_id;

      const idExists = state.conversations.find(
        (conversation: Conversation) =>
          conversationId === conversation.conversation_id,
      );

      if (!idExists) conversationId = DEFAULT_CHAT_ID;

      const updatedConversations: Conversation[] = state.conversations.map(
        (conversation: Conversation) => {
          if (conversationId === conversation.conversation_id) {
            return {
              ...conversation,
              conversation_id:
                conversationId === DEFAULT_CHAT_ID
                  ? action.payload.conversation_id // of the message
                  : conversationId, // of the top conversation
              messages: [...(conversation.messages || []), action.payload],
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;

      if (role === ConversationRole.AGENT) {
        state.avatarSpeech = content;
      }

      const currentConversationId = conversationId || DEFAULT_CHAT_ID;

      const newThreadStatusRegistry = {
        ...state.threadsStatusRegistry,
        [currentConversationId]: {
          ...state.threadsStatusRegistry[currentConversationId],
          isSubmitHappened: false,
        },
      };
      state.threadsStatusRegistry = newThreadStatusRegistry;
    },
    // operation_type = CREATE creates a new message
    createConversationChatMessage: (state, action: PayloadAction<Message>) => {
      const { conversation_id, operation_type } = action.payload;

      if (operation_type !== OperationType.CREATE) {
        return state;
      }

      const idExists = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      const searchID = idExists ? conversation_id : DEFAULT_CHAT_ID;

      // either insert or replace default conversation
      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation.conversation_id === searchID) {
            return {
              ...conversation,
              messages: [...(conversation.messages || []), action.payload],
            };
          }
          return conversation;
        },
      );

      const { messageToStream = '' } = getStreamableAvatarChunks(
        action.payload,
      );

      state.conversations = updatedConversations;
      state.avatarSpeech = messageToStream;
    },
    // operation_type = REPLACE replaces message payload with
    // the new payload in streaming fashion
    replaceConversationChatMessage: (state, action: PayloadAction<Message>) => {
      const {
        message_id,
        conversation_id = DEFAULT_CHAT_ID,
        operation_type,
      } = action.payload;

      if (operation_type !== OperationType.REPLACE) {
        return state;
      }

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation_id === conversation.conversation_id,
      );

      if (focusConversation) {
        const updatedMessages = focusConversation.messages?.map(
          (message: Message) => {
            if (message_id === message.message_id) {
              return action.payload;
            }
            return message;
          },
        );

        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (conversation.conversation_id === conversation_id) {
              return {
                ...conversation,
                messages: updatedMessages,
              };
            }
            return conversation;
          },
        );

        state.conversations = updatedConversations;
      }
    },
    // operation_type = APPEND deeply merges arriving payload objects
    appendConversationChatMessage: (state, action: PayloadAction<Message>) => {
      const {
        message_id,
        operation_type,
        conversation_id = DEFAULT_CHAT_ID,
      } = action.payload;

      if (operation_type !== OperationType.APPEND) {
        return state;
      }

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      if (focusConversation) {
        // find message to append to
        const updatingMessage = focusConversation.messages?.find(
          (message: Message) => message.message_id === message_id,
        );

        // obtain chunk to stream speech to avatar
        const { messageToStream = '' } = getStreamableAvatarChunks(
          action.payload,
        );

        const updatedPayload = updatingMessage?.payload
          ? recursiveDeepMerge(
              updatingMessage?.payload || {},
              action.payload.payload || {},
            )
          : action.payload.payload;

        // reconstruct current conversation messages array
        const updatedMessages = focusConversation.messages?.map(
          (message: Message) => {
            if (message_id === message.message_id) {
              return {
                ...updatingMessage,
                ...action.payload,
                payload: updatedPayload,
              };
            }
            return message;
          },
        );

        // update state conversations array with updated focus conversation
        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (conversation_id === conversation.conversation_id) {
              return {
                ...conversation,
                messages: updatedMessages,
              };
            }
            return conversation;
          },
        );

        state.conversations = updatedConversations;
        state.avatarSpeech = messageToStream;
      }
    },
    // handle update operation type
    updateConversationChatMessage: (state, action: PayloadAction<Message>) => {
      const {
        message_id,
        operation_type,
        conversation_id = DEFAULT_CHAT_ID,
      } = action.payload;

      if (operation_type !== OperationType.UPDATE) {
        return state;
      }

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      if (focusConversation) {
        // find message to append to
        const updatingMessage = focusConversation.messages?.find(
          (message: Message) => message.message_id === message_id,
        );

        // obtain chunk to stream speech to avatar
        const { messageToStream = '' } = getStreamableAvatarChunks(
          action.payload,
        );

        const updatedPayload = recursiveUpdatePayload(
          updatingMessage?.payload || {},
          action.payload.payload || {},
        );

        // reconstruct current conversation messages array
        const updatedMessages = updatingMessage
          ? focusConversation.messages?.map((message: Message) => {
              if (message_id === message.message_id) {
                return {
                  ...updatingMessage,
                  ...action.payload,
                  payload: updatedPayload,
                };
              }
              return message;
            })
          : [...(focusConversation?.messages || []), action.payload];

        // update state conversations array with updated focus conversation
        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (conversation_id === conversation.conversation_id) {
              return {
                ...conversation,
                messages: updatedMessages,
              };
            }
            return conversation;
          },
        );

        state.conversations = updatedConversations;
        state.avatarSpeech = messageToStream;
      }
    },
    replaceConversationChatTemporaryMessageId: (
      state,
      action: PayloadAction<{
        temporaryMessageId: string;
        newMessage: Message;
      }>,
    ) => {
      const { temporaryMessageId, newMessage } = action.payload;
      const { conversation_id = DEFAULT_CHAT_ID } = newMessage;

      const updatedConversations = state.conversations.map((conversation) => {
        if (conversation.conversation_id !== conversation_id) {
          return conversation;
        }

        const updatedMessages = (conversation.messages || []).map((message) =>
          message.message_id === temporaryMessageId ? newMessage : message,
        );

        return {
          ...conversation,
          messages: updatedMessages,
        };
      });

      state.conversations = updatedConversations;
    },
    // chunk-by-chunk chit-chat streaming / add message chunk
    addConversationChatMessageChunk: (
      state,
      action: PayloadAction<MessageChunk>,
    ) => {
      const { message_id, conversation_id, content, task_id } = action.payload;

      const conversationId = conversation_id || DEFAULT_CHAT_ID;

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversationId,
      );

      if (!focusConversation) {
        return state;
      }

      const updatedMessages = (focusConversation.messages || []).map(
        (message: Message) => {
          if (message.message_id === message_id) {
            return {
              ...message,
              // (olha): it's a small workaround since if BE sends task_id, the message_type actually was changed
              message_type: task_id
                ? MessageType.TASK_CREATED
                : MessageType.CONVERSATION,
              task_id,
              content: message.content + action.payload.content,
            };
          }
          return message;
        },
      );

      const messageToStream = removeReferencesInBrackets(content);

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversationId === conversation.conversation_id) {
            return {
              ...focusConversation,
              messages: updatedMessages,
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
      state.avatarSpeech = messageToStream;
    },
    // chunk-by-chunk streaming / finalize message chunk
    addConversationChatMessageFooter: (
      state,
      action: PayloadAction<MessageFooter>,
    ) => {
      const {
        conversation_id = DEFAULT_CHAT_ID,
        message_id,
        is_final_answer = false,
      } = action.payload;

      if (!conversation_id) {
        return state;
      }

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      let finishAvatarSpeech = '';
      const updatedMessages = (focusConversation?.messages || []).map(
        (message: Message) => {
          if (message.message_id === message_id) {
            const avatarContentBlock = message.content.trim();
            if (
              !(
                avatarContentBlock?.endsWith('.') ||
                avatarContentBlock?.endsWith('?') ||
                avatarContentBlock?.endsWith('!')
              )
            ) {
              // Avatar will start speaking the last sentence if it didn't
              // once we have the content ready & finalized by the message footer.
              // Adding the period to add sentence boundary to the awaiting
              // unfinished sentence in useAvatarSpeech hook.
              finishAvatarSpeech = '.';
            }
            return { ...message, ...action.payload, is_final_answer };
          }
          return message;
        },
      );

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation.conversation_id === conversation_id) {
            return {
              ...conversation,
              messages: updatedMessages,
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
      state.avatarSpeech = finishAvatarSpeech;
    },
    updateConversationChatMessageFeedback: (
      state,
      action: PayloadAction<UserConversationMessageFeedback>,
    ) => {
      const { conversation_id, message_id, feedback, taskId } = action.payload;

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      if (!focusConversation) {
        return state;
      }

      if (taskId) {
        const updatedTasks = state.tasks.map((task) => {
          if (task.task_id === taskId) {
            return {
              ...task,
              feedback,
            };
          }
          return task;
        });

        state.tasks = updatedTasks;
      }

      const updatedMessages = focusConversation.messages?.map(
        (message: Message) => {
          if (message.message_id === message_id) {
            return {
              ...message,
              feedback,
            };
          }
          return message;
        },
      );

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation_id === conversation.conversation_id) {
            return {
              ...conversation,
              messages: updatedMessages,
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
    },
    updateConversationChatMessageFeedbackType: (
      state,
      action: PayloadAction<UserConversationMessageFeedbackType>,
    ) => {
      const { conversation_id, message_id, feedback_type } = action.payload;

      const focusConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversation_id,
      );

      if (!focusConversation) {
        return state;
      }

      const updatedMessages = focusConversation.messages?.map(
        (message: Message) => {
          if (message.message_id === message_id) {
            return {
              ...message,
              feedback_type,
            };
          }
          return message;
        },
      );

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation_id === conversation.conversation_id) {
            return {
              ...conversation,
              messages: updatedMessages,
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
    },
    /**
     * Hierarchical tasks routines for userTasks.tasks
     */
    addTaskChat: (state, action: PayloadAction<ApiTaskSelectable>) => {
      const { parent_conversation_id } = action.payload as ApiTaskSelectable;

      // todo: create a utility function to convert between types
      // construct a minimum chat task
      const chatTaskMinimum = {
        task_id: action.payload.task_id,
        user_id: action.payload.user_id,
        task_hash: action.payload.task_hash,
        state: action.payload.state,
        skill: action.payload.skill,
        requires_attention: action.payload.requires_attention,
        conversation_id: action.payload.conversation_id,
        created_at: action.payload.created_at,
        parent_conversation_id,
      } as ApiMinimumTask;

      // add minimum task to a conversation & its list of tasks
      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation.conversation_id === parent_conversation_id) {
            const taskExists = conversation.tasks?.some(
              (task) => task.task_id === action.payload.task_id,
            );

            return taskExists
              ? conversation
              : {
                  ...conversation,
                  tasks: [chatTaskMinimum, ...(conversation.tasks || [])],
                };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;

      if (
        !state.tasks.some((task) => task.task_id === action.payload.task_id)
      ) {
        state.tasks = [...state.tasks, action.payload];
      }

      state.shouldAnimate = ['task', action.payload.task_id || ''];
    },
    // chunk-by-chunk chit-chat streaming / add message chunk
    addTaskChatMessageChunk: (state, action: PayloadAction<MessageChunk>) => {
      const { message_id, task_id, content } = action.payload as MessageChunk;

      const foundTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (foundTask) {
        const messages = foundTask.conversation?.messages || [];
        const updatedTaskMessages = messages.map((message: Message) => {
          if (message_id === message.message_id) {
            return {
              ...message,
              content: message.content + content,
            };
          }
          return message;
        });

        const updatedTasks = state.tasks.map(
          (task: Partial<ApiTaskSelectable>) => {
            if (task.task_id === task_id) {
              return {
                ...task,
                user_id: task.user_id,
                conversation: {
                  ...task.conversation,
                  messages: updatedTaskMessages,
                },
              } as ApiTaskSelectable;
            }
            return task;
          },
        );

        state.tasks = updatedTasks;
      }
    },
    addMessageToTaskChat: (state, action: PayloadAction<Message>) => {
      const { task_id } = action.payload;

      if (!task_id) {
        return state;
      }

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];

      for (const taskItem of state.tasks) {
        const {
          conversation: currentTaskConversation,
          task_id: currentTaskId,
        } = taskItem;

        if (currentTaskId === task_id) {
          const newConversation = currentTaskConversation || {
            conversation_id: action.payload.conversation_id,
            user_id: taskItem.user_id || action.payload.from_user_id,
            timestamp: new Date().toISOString(),
            messages: [],
          };

          if (!newConversation.messages) {
            newConversation.messages = [action.payload];
          } else {
            const messageIds = newConversation.messages.map(
              (message: Message) => message.message_id,
            );
            if (messageIds.includes(action.payload.message_id)) {
              newConversation.messages = newConversation.messages.map(
                (message: Message) => {
                  if (message.message_id === action.payload.message_id) {
                    return {
                      ...message,
                      ...action.payload,
                    };
                  }
                  return message;
                },
              );
            } else {
              newConversation.messages = [
                ...newConversation.messages,
                action.payload,
              ];
            }
          }

          taskItem.conversation = newConversation;
          updatedTasks.push({
            ...taskItem,
            conversation: newConversation,
          });
        } else {
          updatedTasks.push(taskItem);
        }
      }

      state.tasks = updatedTasks;
    },
    updateTaskChatMessage: (
      state,
      action: PayloadAction<{
        task_id: string;
        user_id: string;
        message: Partial<Message>;
      }>,
    ) => {
      const focusTask = state.tasks.find(
        (task) => task.task_id === action.payload.task_id,
      );

      if (!focusTask) return state;

      const { message_id } = action.payload.message;
      const focusMessage = focusTask?.conversation?.messages?.find(
        (message) => message.message_id === message_id,
      );

      if (!focusMessage) return state;

      const updatedFocusMessage = {
        ...focusMessage,
        ...action.payload.message,
      };

      const updatedFocusTaskMessages = (
        focusTask?.conversation?.messages || []
      ).map((message) => {
        return message.message_id === message_id
          ? updatedFocusMessage
          : message;
      });

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id: action.payload.user_id,
        messages: updatedFocusTaskMessages,
      };

      const updatedTasks = state.tasks.map((task) => {
        return task.task_id === action.payload.task_id ? focusTask : task;
      });

      state.tasks = updatedTasks;
    },
    replaceTaskChatTemporaryMessageId: (
      state,
      action: PayloadAction<{
        temporaryMessageId: string;
        newMessage: Message;
      }>,
    ) => {
      const { temporaryMessageId, newMessage } = action.payload;
      const { user_id, task_id } = newMessage;

      const updatedTasks = state.tasks.map((task) => {
        if (task.task_id !== task_id) {
          return task;
        }

        const updatedMessages = (task.conversation?.messages || []).map(
          (message) =>
            message.message_id === temporaryMessageId ? newMessage : message,
        );

        return {
          ...task,
          conversation: {
            ...task.conversation,
            user_id,
            messages: updatedMessages,
          },
        };
      });

      state.tasks = updatedTasks;
    },
    markTaskChatMessagesAsRead: (
      state,
      action: PayloadAction<{
        task_id: string;
        user_id: string;
        is_read: boolean;
      }>,
    ) => {
      const { task_id, is_read, user_id } = action.payload;
      const taskToUpdate = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (taskToUpdate) {
        const messagesToMark = (taskToUpdate?.conversation?.messages || []).map(
          (message: Message) => {
            return {
              ...message,
              is_read,
            };
          },
        );

        taskToUpdate.conversation = {
          ...(taskToUpdate?.conversation || {}),
          user_id,
          messages: messagesToMark,
        };

        const updatedTasks: Partial<ApiTaskSelectable>[] = [];
        for (const task of state.tasks) {
          if (task.task_id === task_id) {
            updatedTasks.push(taskToUpdate);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
    updateTaskChatFeedbackType: (
      state,
      action: PayloadAction<UserTaskFeedback>,
    ) => {
      const { feedback_type, task_id } = action.payload;
      const updatedTasks = state.tasks.map(
        (task: Partial<ApiTaskSelectable>) => {
          if (task.task_id === task_id) {
            return {
              ...task,
              feedback_type,
            };
          }
          return task;
        },
      );

      state.tasks = updatedTasks;
    },
    // operation_type = REPLACE replaces task chat message.
    // TODO(olha): needs refactoring
    replaceTaskChatMessage: (state, action: PayloadAction<Message>) => {
      const { task_id, message_id, operation_type, user_id } = action.payload;

      const focusTask =
        state.tasks.find(
          (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
        ) || {};

      const messageIdExists = focusTask?.conversation?.messages?.some(
        (message: Message) => message.message_id === message_id,
      );

      if (!(messageIdExists && operation_type === OperationType.REPLACE)) {
        // TODO(olha): add condition to create a new message if !messageIdExists
        const newMessages = [
          ...(focusTask?.conversation?.messages || []),
          { ...action.payload },
        ];

        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id,
          messages: newMessages,
        };

        const updatedTasks: Partial<ApiTaskSelectable>[] = [];
        for (const task of state.tasks) {
          if (task.task_id === task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
        return;
      }

      const updatedMessages = (focusTask?.conversation?.messages || []).map(
        (message: Message) => {
          if (message.message_id === message_id) {
            return action.payload;
          }
          return message;
        },
      );

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id,
        messages: updatedMessages,
      };

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      for (const task of state.tasks) {
        if (task.task_id === task_id) {
          updatedTasks.push(focusTask);
        } else {
          updatedTasks.push(task);
        }
      }

      state.tasks = updatedTasks;
    },
    // operation_type = APPEND appends message to task chat
    appendTaskChatMessage: (state, action: PayloadAction<Message>) => {
      const { task_id, user_id, message_id, operation_type } = action.payload;

      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (!(focusTask && operation_type === OperationType.APPEND)) {
        return state;
      }

      const focusTaskMessages = focusTask?.conversation?.messages || [];
      const updatableMessage = focusTaskMessages.find(
        (message: Message) => message.message_id === message_id,
      );

      if (!updatableMessage) {
        focusTask.conversation = {
          ...(focusTask.conversation || {}),
          user_id,
          messages: [action.payload],
        };
      } else {
        const mergedMessagePayload = recursiveDeepMerge(
          updatableMessage?.payload || {},
          action.payload?.payload || {},
        );

        const updatedMessage = {
          ...updatableMessage,
          ...action.payload,
          payload: mergedMessagePayload,
        };

        const updatedMessages = (focusTask?.conversation?.messages || []).map(
          (message: Message) => {
            if (message.message_id === action.payload.message_id) {
              return updatedMessage;
            }
            return message;
          },
        );

        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id,
          messages: updatedMessages,
        };
      }

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      for (const task of state.tasks) {
        if (task_id === task.task_id) {
          updatedTasks.push(focusTask);
        } else {
          updatedTasks.push(task);
        }
      }

      state.tasks = updatedTasks;
    },
    // operation_type = CREATE
    createTaskChatMessage: (state, action: PayloadAction<Message>) => {
      const { task_id, operation_type, user_id } = action.payload;

      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (!(focusTask && operation_type === OperationType.CREATE)) {
        return state;
      }

      const updatedMessages = [
        ...(focusTask?.conversation?.messages || []),
        action.payload,
      ];

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id,
        messages: updatedMessages,
      };

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      for (const task of state.tasks) {
        if (task_id === task.task_id) {
          updatedTasks.push(focusTask);
        } else {
          updatedTasks.push(task);
        }
      }

      state.tasks = updatedTasks;
    },
    updateTaskChatMessageFeedbackType: (
      state,
      action: PayloadAction<UserTaskChatMessageFeedback>,
    ) => {
      const { message_id, user_id, task_id } = action.payload;
      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (focusTask) {
        const focusTaskMessages: Message[] =
          focusTask?.conversation?.messages || [];

        const updatedFocusTaskMessages = focusTaskMessages.map(
          (message: Message) => {
            if (message.message_id === message_id) {
              return {
                ...message,
                ...action.payload,
              };
            }
            return message;
          },
        );

        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id,
          messages: updatedFocusTaskMessages,
        };

        const updatedTasks: Partial<ApiTaskSelectable>[] = [];
        for (const task of state.tasks) {
          if (task_id === task.task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
    updateTaskChatState: (
      state,
      action: PayloadAction<Partial<ApiTaskSelectable>>,
    ) => {
      const {
        task_id,
        parent_conversation_id,
        state: taskState,
      } = action.payload as Partial<ApiTask>;

      if (!task_id) return state;

      taskGTMEvent(action.payload);

      const updatedTasks: Partial<ApiTaskSelectable>[] = state.tasks.map(
        (task: Partial<ApiTaskSelectable>) => {
          if (task_id === task.task_id) {
            return { ...task, state: taskState };
          }
          return task;
        },
      );

      state.tasks = updatedTasks;

      let updatedTopConversationTasks: ApiMinimumTask[] = [];
      let updatedTopConversations: Conversation[] = [];

      // top conversation task belongs to
      const topConversation = state.conversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === parent_conversation_id,
      );

      if (topConversation) {
        let minimumTaskExists = false;

        updatedTopConversationTasks = (topConversation.tasks || []).map(
          (minimumTask: ApiMinimumTask) => {
            if (minimumTask.task_id === task_id) {
              minimumTaskExists = true;
              // only override values when they are defined
              // otherwise use old values
              return {
                ...minimumTask,
                task_hash: action.payload.task_hash || minimumTask.task_hash,
                state: action.payload.state || minimumTask.state,
                skill: action.payload.skill || minimumTask.skill,
                requires_attention:
                  action.payload.requires_attention ||
                  minimumTask.requires_attention,
              };
            }
            return minimumTask;
          },
        );

        // add a minimum task if it did not exist
        // and animate
        if (!minimumTaskExists) {
          const minimumTask = convertToMinimumApiTask(action.payload);
          updatedTopConversationTasks = [
            ...(topConversation.tasks || []),
            minimumTask,
          ];

          state.shouldAnimate = ['task', task_id];
        }

        // updated top conversations
        updatedTopConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (conversation.conversation_id === parent_conversation_id) {
              return {
                ...conversation,
                tasks: updatedTopConversationTasks,
              };
            }
            return conversation;
          },
        );

        state.conversations = updatedTopConversations;
      }
    },
    updateConversationsState: (
      state,
      action: PayloadAction<Partial<Conversation>>,
    ) => {
      const { conversation_id } = action.payload as Partial<Conversation>;

      if (!conversation_id) return state;

      const updatedTopConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversation.conversation_id === conversation_id) {
            return {
              ...conversation,
              ...action.payload,
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedTopConversations;
    },
    removeAllMessagesFromTaskChat: (
      state,
      action: PayloadAction<{ task_id: string; user_id: string }>,
    ) => {
      const updatedTasks: Partial<ApiTaskSelectable>[] = [];

      const { task_id, user_id } = action.payload;
      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (focusTask) {
        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id,
          messages: [],
        };

        for (const task of state.tasks) {
          if (task.task_id === task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },

    // New slices for handling new stream approach. All slices need deep refactoring
    addConversationChatMessageContentChunk: (
      state,
      action: PayloadAction<MessageChunk>,
    ) => {
      const { message_id, conversation_id, content, task_id } = action.payload;

      const conversationId = conversation_id || DEFAULT_CHAT_ID;

      const messageToStream = removeReferencesInBrackets(content);

      const updatedConversations = state.conversations.map(
        (conversation: Conversation) => {
          if (conversationId === conversation.conversation_id) {
            return {
              ...conversation,
              messages:
                conversation.messages?.map((message) =>
                  message.message_id === message_id
                    ? {
                        ...message,
                        task_id,
                        content: message.content + action.payload.content,
                      }
                    : message,
                ) || [],
            };
          }
          return conversation;
        },
      );

      state.conversations = updatedConversations;
      state.avatarSpeech = messageToStream;
    },

    addTaskChatMessageContentChunk: (
      state,
      action: PayloadAction<MessageChunk>,
    ) => {
      const { task_id, content, message_id } = action.payload;

      const updatedTasks = state.tasks.map((task) =>
        task.task_id === task_id
          ? {
              ...task,
              conversation: {
                ...task.conversation,
                // TODO(olha): it's a temporary workaround. With new streaming we don't receive a user_id in chunk. Need to be check why user_id can be undefined in the task model
                user_id: task.user_id || '',
                messages:
                  task.conversation?.messages?.map((item) =>
                    item.message_id === message_id
                      ? {
                          ...item,
                          content: item.content + content,
                        }
                      : item,
                  ) || [],
              },
            }
          : task,
      );

      state.tasks = updatedTasks;
    },
  },

  extraReducers: (builder) => {
    builder
      /**
       * Newest hierarchical conversational routines for userTasks.conversations
       */
      .addCase(fetchNextTopConversations.fulfilled, (state, action) => {
        const {
          conversations = [],
          pageToken = '',
          reload = false,
        } = action.payload as {
          pageToken: string;
          conversations: Conversation[];
          reload?: boolean;
        };

        state.conversations = reload
          ? [...conversations]
          : [...state.conversations, ...conversations];
        state.pageToken = pageToken;
      })
      .addCase(fetchFilteredConversationTasks.fulfilled, (state, action) => {
        // attn: page token is not returned to make this a complete feature
        const { conversations, reload, pageToken } = action.payload as {
          pageToken: string;
          conversations: Conversation[];
          reload?: boolean;
        };

        state.conversations = reload
          ? [...conversations]
          : [...state.conversations, ...conversations];
        state.pageToken = pageToken;
      })
      .addCase(fetchUpdateConversationById.fulfilled, (state, action) => {
        const apiConversation = action.payload as Conversation;

        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (
              conversation.conversation_id === apiConversation.conversation_id
            ) {
              return {
                ...conversation,
                ...apiConversation,
              };
            }
            return conversation;
          },
        );

        state.conversations = updatedConversations;
      })
      /**
       * Existing tasks routines copied from userTasks.tasks
       */
      .addCase(fetchNextPageTasks.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        const { tasks = [], pageToken = '' } =
          action.payload as TasksByPageResponse;
        if (pageToken === state.pageToken) {
          return state;
        }

        return {
          ...state,
          tasks: [...state.tasks, ...tasks],
          pageToken,
        };
      })
      .addCase(fetchTaskById.fulfilled, (state, action) => {
        const arrivedTask = action.payload as ApiTaskSelectable;

        const taskExists = state.tasks.find(
          (task: Partial<ApiTaskSelectable>) =>
            task.task_id === arrivedTask.task_id,
        );

        if (!taskExists) {
          return {
            ...state,
            tasks: [...state.tasks, arrivedTask],
            selectedTaskId: arrivedTask.task_id,
            selectedConversationId: undefined,
          };
        }

        const updatedTasks = state.tasks.map(
          (task: Partial<ApiTaskSelectable>) => {
            if (task.task_id === arrivedTask.task_id) {
              const mergedMessages =
                task.conversation?.messages &&
                arrivedTask.conversation?.messages
                  ? mergeArraysWithSortUniqueElementsByKey<
                      Message,
                      keyof Message,
                      keyof Message
                    >(
                      task.conversation.messages,
                      arrivedTask.conversation.messages,
                      'message_id',
                      'timestamp',
                    )
                  : task.conversation?.messages ||
                    arrivedTask.conversation?.messages ||
                    [];

              return {
                ...task,
                ...arrivedTask,
                conversation: {
                  ...arrivedTask.conversation,
                  // TODO(olha): Need to be check why user_id can be undefined in the task model
                  user_id: arrivedTask.user_id || task.user_id || '',
                  messages: mergedMessages,
                },
              };
            }
            return task;
          },
        );

        return {
          ...state,
          tasks: updatedTasks,
          selectedTaskId: arrivedTask.task_id,
          selectedConversationId: undefined,
        };
      })
      .addCase(fetchUpdateTaskById.fulfilled, (state, action) => {
        const apiTask = action.payload as ApiTaskSelectable;

        const updatedTasks = state.tasks.map(
          (task: Partial<ApiTaskSelectable>) => {
            if (task.task_id === apiTask.task_id) {
              return {
                ...task,
                ...apiTask,
              };
            }
            return task;
          },
        );

        const currentConversation = state.conversations.find(
          (conversation: Conversation) =>
            conversation.conversation_id === apiTask.parent_conversation_id,
        );

        const updatedMinimumTasks = currentConversation?.tasks?.map(
          (minimumTask: ApiMinimumTask) => {
            if (minimumTask.task_id === apiTask.task_id) {
              return {
                ...minimumTask,
                state: apiTask.state,
                task_hash: apiTask.task_hash,
              };
            }
            return minimumTask;
          },
        );

        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (
              conversation.conversation_id ===
              currentConversation?.conversation_id
            ) {
              return {
                ...conversation,
                tasks: updatedMinimumTasks,
              };
            }
            return conversation;
          },
        );

        state.tasks = updatedTasks;
        state.conversations = updatedConversations;
      })
      .addCase(fetchConversationById.pending, (state, action) => {
        state.isFetchingConversation = true;
      })
      .addCase(fetchConversationById.fulfilled, (state, action) => {
        const arrivedConversation = action.payload as Conversation;

        const updatedConversations = state.conversations.map(
          (conversation: Conversation) => {
            if (
              conversation.conversation_id ===
              arrivedConversation.conversation_id
            ) {
              const filteredMessages = arrivedConversation.messages?.filter(
                (message) => message.is_final_answer,
              ) as Message[];

              const mergedMessages =
                conversation.messages && arrivedConversation.messages
                  ? mergeArraysWithSortUniqueElementsByKey(
                      conversation.messages,
                      filteredMessages,
                      'message_id',
                      'timestamp',
                    )
                  : conversation.messages || arrivedConversation.messages || [];

              return {
                ...conversation,
                ...arrivedConversation,
                messages: mergedMessages,
              };
            }
            return conversation;
          },
        );

        return {
          ...state,
          selectedConversationId: arrivedConversation.conversation_id,
          selectedTaskId: undefined,
          conversations: updatedConversations,
          isFetchingConversation: false,
        };
      })
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        tasksApi.endpoints.getTaskById.matchFulfilled,
        (state, action) => {
          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === action.payload.task_id) {
                return { ...task, ...action.payload };
              }
              return task;
            },
          );
          return { ...state, tasks: updatedTasks };
        },
      )
      // todo: test that all messages become unread in a task
      // attn: this cannot be done well if we don't have partial tasks
      .addMatcher(
        tasksApi.endpoints.markAllReadTaskMessages.matchFulfilled,
        (state, action) => {
          const foundTask = state.tasks.find(
            (task: Partial<ApiTaskSelectable>) =>
              task.task_id === action.payload,
          );
          if (!foundTask) return state;

          const foundTaskMessages = foundTask?.conversation?.messages?.map(
            (message: Message) => {
              return { ...message, is_read: true };
            },
          );

          if (
            !foundTaskMessages ||
            foundTaskMessages.length === 0 ||
            !foundTask.user_id
          ) {
            return state;
          }

          foundTask.conversation = {
            ...(foundTask.conversation || {}),
            user_id: foundTask.user_id,
            messages: foundTaskMessages,
          };

          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === foundTask?.task_id) {
                return foundTask;
              }
              return task;
            },
          );

          return { ...state, tasks: updatedTasks };
        },
      )
      // todo: test that all messages become unread in a task
      // attn: this cannot be done well if we don't have partial tasks
      .addMatcher(
        tasksApi.endpoints.markAllUnreadTaskMessages.matchFulfilled,
        (state, action) => {
          const foundTask = state.tasks.find(
            (task: Partial<ApiTaskSelectable>) =>
              task.task_id === action.payload,
          );
          if (!foundTask) return state;

          const foundTaskMessages = foundTask?.conversation?.messages?.map(
            (message: Message) => {
              return { ...message, is_read: false };
            },
          );

          if (
            !foundTaskMessages ||
            foundTaskMessages.length === 0 ||
            !foundTask.user_id
          ) {
            return state;
          }

          foundTask.conversation = {
            ...(foundTask.conversation || {}),
            user_id: foundTask.user_id,
            messages: foundTaskMessages,
          };

          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === foundTask?.task_id) {
                return foundTask;
              }
              return task;
            },
          );

          return { ...state, tasks: updatedTasks };
        },
      )
      .addMatcher(
        tasksApi.endpoints.getTasksByUserId.matchFulfilled,
        (state, action) => {
          return { ...state, tasks: action.payload };
        },
      )
      // att: just in case query while we are migrating to tasks thunks
      .addMatcher(
        tasksApi.endpoints.getNextPageTasksByUserId.matchFulfilled,
        (state, action) => {
          const { tasks: newPageTasks, pageToken } = action.payload;
          return {
            ...state,
            tasks: [...state.tasks, ...newPageTasks],
            pageToken,
          };
        },
      )
      .addMatcher(
        tasksApi.endpoints.updateTask.matchFulfilled,
        (state, action) => {
          const updatedTasks: Partial<ApiTaskSelectable>[] = [];
          const { task_id } = action.payload;

          for (const taskItem of state.tasks) {
            if (task_id === taskItem.task_id) {
              updatedTasks.push({
                ...taskItem,
                ...action.payload,
              });
            } else {
              updatedTasks.push(taskItem);
            }
          }

          return { ...state, tasks: updatedTasks };
        },
      )
      .addMatcher(
        tasksApi.endpoints.updateTaskField.matchFulfilled,
        (state, action) => {
          const updatedTasks: Partial<ApiTaskSelectable>[] = [];
          const { task_id } = action.payload;

          for (const taskItem of state.tasks) {
            if (task_id === taskItem.task_id) {
              updatedTasks.push({
                ...taskItem,
                ...action.payload,
              });
            } else {
              updatedTasks.push(taskItem);
            }
          }

          return { ...state, tasks: updatedTasks };
        },
      );
  },
});

export const userChatsState = (state: RootState) =>
  state.userChats as UserChatsState;
export const userChatsStateThreadsStatusRegistry = (state: RootState) =>
  state.userChats.threadsStatusRegistry;

export const {
  addConversationChat,
  addTaskChat,
  addMinimumTaskToConversationChat,
  addTaskToConversationChat,
  addTaskChatMessageChunk,
  addConversationChatMessageChunk,
  addConversationChatMessageFooter,
  addMessageToConversationChat,
  addMessageToTaskChat,
  appendConversationChatMessage,
  archiveConversationTask,
  updateConversationChatMessage,
  appendTaskChatMessage,
  clearConversationChatMessages,
  createConversationChatMessage,
  createTaskChatMessage,
  replaceTaskChatTemporaryMessageId,
  replaceConversationChatTemporaryMessageId,
  markTaskChatMessagesAsRead,
  removeAllMessagesFromTaskChat,
  replaceConversationChatMessage,
  replaceTaskChatMessage,
  replaceDefaultChat,
  updateTaskChatMessage,
  updateTaskChatState,
  updateConversationsState,
  updateConversationChatMessageFeedbackType,
  updateTaskChatMessageFeedbackType,
  updateTaskChatFeedbackType,
  updateUserChatsState,
  setAvatarSpeech,
  setThreadStatus,
  setShouldAnimate,
  updateConversationChatMessageFeedback,
  addConversationChatMessageContentChunk,
  addTaskChatMessageContentChunk,
} = userChatsSlice.actions;

export default userChatsSlice.reducer;
