import {
  AppUser,
  Message,
  MessageChannel,
  ConversationRole,
  MessageDebugData,
  Side,
  ModelFeedback,
  ConversationEngineResponseWithTraceId,
  isConversationEngineSchedulingData,
  Tag,
  isString,
  isObject,
  isChoices,
  Choice,
  Choices,
  ChoicesCard,
  MessageType,
  MessageSkill,
  GroupedMessagesByDate,
  isMessageWithCreationTaskId,
  TaskSkill,
  AiGenerationData,
  ExternalModelReference,
} from 'src/types';
import { getUserFirstName, uppercaseFirstLetter } from './general';
// import { TIMESTAMP_FORMAT } from 'src/constants/global';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import log from 'src/utils/logger';
import { externalModelsSet } from 'src/constants';

dayjs.extend(utc);

/**
 * getMessageSide() finds out the side the message bubble should show up on.
 * @param user
 * @param role
 * @param fromUserId
 * @param prevFromUserId
 * @returns string
 */
export function getMessageSide(
  user: AppUser,
  role: ConversationRole,
  fromUserId: string,
): Side {
  if (role && role === ConversationRole.AGENT) {
    return Side.LEFT;
  }

  if (user.user_id === fromUserId) {
    return Side.RIGHT;
  }

  return Side.LEFT;
}

/**
 * getMessageAuthor() finds out the user based on the message header info.
 * @param users AppUser[]
 * @param fromUserId string
 * @returns User
 */
export function getMessageAuthor(
  users: AppUser[],
  fromUserId: string,
): AppUser | undefined {
  return users.find((user) => user.user_id === fromUserId);
}

/**
 * getDefaultMessage creates a default message for the chat.
 * @param currentUser User
 * @param agent User
 * @returns ChatMessage
 */
export function getDefaultMessage(
  currentUser: AppUser,
  agent: AppUser,
): Message {
  return {
    user_id: currentUser.user_id,
    from_user_id: agent.user_id,
    to_user_id: currentUser.user_id,
    content: `Hi ${getUserFirstName(
      currentUser,
    )}, <br>what can I help you with today?`,
    role: ConversationRole.AGENT,
    channel: MessageChannel.WEB_APP,
    timestamp: new Date().toISOString(),
    tag: Tag.NEW_CONVERSATION,
  };
}

/**
 * getLatestTaskRelatedMessages retrieves message from last
 * the tag = 'NEW_CONVERSATION'
 * @param messages Message[]
 * @param debug boolean
 * @returns Message[]
 */
export function getLatestTaskRelatedMessages(
  messages: Message[],
  debug = false,
): Message[] {
  const taskRelatedMessages = [];
  let len = messages.length;
  while (len > 0) {
    len--;
    taskRelatedMessages.unshift(messages[len]);
    if (messages[len].tag === Tag.NEW_CONVERSATION) {
      break;
    }
  }
  if (debug) {
    log.debug('Conversation:', taskRelatedMessages);
  }
  return taskRelatedMessages;
}

/**
 * createNewMessage creates a new message
 * @param message Message
 * @param tag string
 * @param user AppUser
 * @param debugData MessageDebugData
 * @param created_task_id string|null|undefined
 * @returns Message
 */
export function createNewMessage(
  content: string,
  tag: string,
  user: AppUser,
  agent: AppUser,
  messageDebugData: MessageDebugData,
  created_task_id: string | null | undefined = null,
  feedbackData?: ModelFeedback,
): Message {
  // const taskId = created_task_id ? { created_task_id: created_task_id } : {};
  // const feedback = feedbackData
  //   ? {
  //       ...feedbackData,
  //       ui_environment: process.env.REACT_APP_ENVIRONMENT || '',
  //       timestamp: feedbackData.timestamp,
  //     }
  //   : undefined;

  return {
    user_id: user.user_id,
    from_user_id: agent.user_id,
    to_user_id: user.user_id,
    channel: MessageChannel.WEB_APP,
    content,
    timestamp: new Date().toISOString(),
    role: ConversationRole.AGENT,
    tag,
    // debug: messageDebugData,
    // feedback,
    // ...taskId,
  };
}

/**
 * fetchAPI() sends data to the endpoint and receives response { data: Object }.
 * Error-handling is delegated to the UI.
 * @param url string
 * @param options Object
 * @returns Promise
 */
export async function fetchAPI(url: string, options: { body?: string }) {
  const response = await fetch(url, {
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...options,
  });

  const result = await response.json();
  return result;
}

/**
 * Fetches with timeout
 * @param url string
 * @param timeout number
 * @returns Promise
 */
export async function fetchAPIWithTimeout(
  url: string,
  options: { body?: string },
  timeoutOptions: { timeout: number },
) {
  const { timeout } = timeoutOptions;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(url, {
    signal: controller.signal,
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...options,
  });
  clearTimeout(id);
  return response;
}

/**
 * loadConversationEngineResponse tries to fetch.
 * @returns ConversationEngineResponseWithTraceId
 */
export async function loadConversationEngineResponse({
  endpoint,
  debug = false,
  options = {},
  timeoutOptions = {
    timeout: 0,
    error: `Bad request with Conversation Engine Endpoint.`,
  },
}: {
  endpoint: string;
  debug: boolean;
  timeoutOptions?: { timeout: number; error?: string };
  options?: { body?: string };
}): Promise<ConversationEngineResponseWithTraceId> {
  try {
    const response = timeoutOptions
      ? await fetchAPIWithTimeout(endpoint, options, timeoutOptions)
      : await fetchAPI(endpoint, options);

    const xTraceId = response.headers.get('x-trace-id') || undefined;
    const json = await response.json();

    // if (json.success) {
    if (debug) {
      log.debug('Server request: ', options.body);
      log.debug('X-Trace-ID', xTraceId);
      log.debug('Server response: ', json);
    }

    if (isConversationEngineSchedulingData(json.data)) {
      if (debug) {
        log.debug('Returning SCHEDULING data.');
      }
      return {
        data: json.data,
        message: json.data.messages.at(-1).content || json.data.task_subject,
        created_task_id:
          json.data.messages.at(-1).created_task_id || json.data.task_id,
        tag: json.data.messages.at(-1).tag || Tag.CONVERSATION,
        xTraceId,
      };
    }

    if (debug) {
      log.debug('Returning CHIT-CHAT data.');
    }
    return {
      data: json.data,
      message: json.data.text,
      tag: Tag.CONVERSATION,
      xTraceId,
    };
    // } else {
    //   throw new Error(timeoutOptions?.error || `Error`);
    // }
  } catch (error: unknown) {
    if (debug) {
      log.debug(error);
    }
    return {
      message: timeoutOptions?.error,
      tag: Tag.ERROR,
      xTraceId: undefined,
    };
  }
}

/**
 * Possible Fetch API Solution for Retries (if backend does not fix it)
 * Todo(ella): can have our own retries if needed.
 * @param URL string
 * @param options Object
 */
export function fetchApiWithRetries(URL: string, options: { body: string }) {
  const fetchPromise = fetch(URL, {
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const warningPromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: 'I am working on it...',
          tag: Tag.ERROR,
        }),
      3000,
    );
  });

  const sorryPromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: 'Sorry, I am working on it...',
          tag: Tag.ERROR,
        }),
      8000,
    );
  });

  const mistakePromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: `I'm sorry, it seems I've run into a problem. Could you please re-ask your question?`,
          tag: Tag.ERROR,
        }),
      11000,
    );
  });

  Promise.race([fetchPromise, warningPromise, sorryPromise, mistakePromise])
    .then((result) => {
      // Handle success
      log.debug(result);
    })
    .catch((error) => {
      // Handle error
    });
}

/**
 * getReadableKey() returns readable key.
 * @param key string
 * @returns string
 */
export function getReadableKey(key: string): string {
  return key
    .split('_')
    .map((word) => uppercaseFirstLetter(word))
    .join(' ');
}

/**
 * flattenObject() converts message to a readable format.
 * If you omit, it only removes the key, but adds the value.
 * If you ignore, it removes the key and the value.
 * @param obj Record
 * @param indent number
 * @returns string
 */
export const OMIT_MESSAGE_KEYS = [
  'data',
  'metadata_info',
  'actions',
  'search_result',
  'summary',
  'snippet',
  'generative_ai_result',
  'content',
  'code_snippets',
  'raw_text',
  'references',
  'web_page_references',
  'combined_references',
  'key',
];
export const IGNORE_MESSAGE_KEYS = [
  'query',
  'payload_type',
  'width',
  'height',
  'progress',
  'version',
  'model',
  'words_written_count',
  'words_read_count',
  'domain',
  'source',
  'favicon',
  'title',
  'order',
];

export function flattenObject(
  obj: Record<string, unknown>,
  indent = 0,
): string {
  let result = '';

  for (const key in obj) {
    if (!IGNORE_MESSAGE_KEYS.includes(key)) {
      const readableValue = isString(obj[key])
        ? uppercaseFirstLetter(obj[key] as string)
        : obj[key];
      const readableKey = OMIT_MESSAGE_KEYS.includes(key)
        ? ''
        : `${''.repeat(indent)}${getReadableKey(key)}: \n`;

      if (Array.isArray(readableValue)) {
        for (const [index, item] of readableValue.entries()) {
          if (isObject(item)) {
            if (!OMIT_MESSAGE_KEYS.includes(key)) {
              result += `${''.repeat(indent)}${key}[${index + 1}]:\n`;
            }
            result += `${flattenObject(
              item as Record<string, unknown>,
              indent + 1,
            )}`;
          } else {
            result += `${''.repeat(indent)}${key}[${index + 1}]: ${item}\n`;
          }
        }
      } else if (isObject(readableValue)) {
        result += `${readableKey}${flattenObject(
          readableValue as Record<string, unknown>,
          indent + 2,
        )}`;
      } else {
        result += `${''.repeat(indent)}${readableKey} ${readableValue}\n\n`;
      }
    }
  }

  return result;
}

/**
 * updateOptionSelectedValueForKey() deeply updates object.
 * @param obj ChoicesCard|Choices|Choice
 * @param selectKey string
 * @param selectValue string
 * @param selected boolean
 */
export function updateOptionSelectedValueForKey(
  obj: Choice | Choices | ChoicesCard,
  selectKey: string,
  selectValue: string,
  selected: boolean,
  multiSelect: boolean,
): Choice | Choices | ChoicesCard {
  if (!isObject(obj) || obj === null) {
    return obj;
  }

  // Check if obj is Choices and has choice_list property
  if ('choice_list' in obj && obj.select_key === selectKey && isChoices(obj)) {
    const updatedChoiceList = (obj?.choice_list || []).map((choice) => {
      if (choice.select_value === selectValue) {
        return { ...choice, selected };
      } else if (!multiSelect && selected) {
        return { ...choice, selected: false };
      }
      return choice;
    });

    // Create a new object with the updated choice_list
    return { ...obj, choice_list: updatedChoiceList };
  }

  // If obj is Choice or other types, recursively update
  const updatedObj = Array.isArray(obj) ? [] : {};
  for (const [key, value] of Object.entries(obj)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isObject(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedObj[key] = updateOptionSelectedValueForKey(
        value,
        selectKey,
        selectValue,
        selected,
        multiSelect,
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedObj[key] = value;
    }
  }

  return updatedObj;
}

/**
 * addWaitingMessage() adds triple dot at the end of the messages array
 * and removes not needed messages, also ignores them.
 * @param messages Array<Message>
 * @param ignoreMessages boolean
 * @returns Array<Message>
 */
// TODO(olha): deprecated
export function addWaitingMessage(
  messages: Message[],
  ignoreMessages = false,
): Message[] {
  // attn: removing waiting message for testing reasons
  // to figure out the delay
  return messages;

  /*
  const lastMessage = messages?.at(-1);
  if (lastMessage) {
    const waitingTimestamp = dayjs().utc().format(TIMESTAMP_FORMAT);

    return lastMessage?.role === ConversationRole.USER && !ignoreMessages
      ? [
          ...messages,
          {
            tag: Tag.WAITING,
            content: '',
            timestamp: waitingTimestamp,
            role: ConversationRole.AGENT,
            user_id: lastMessage.user_id,
            from_user_id: lastMessage.to_user_id,
            to_user_id: lastMessage.from_user_id,
            channel: MessageChannel.WEB_APP,
          },
        ]
      : messages;
  }
  return messages;
  */
}

/**
 * addEmptyWaitingMessage() adds empty message at the end of the messages array
 * and removes not needed messages, also ignores them. Used in the Flat dasign
 * @param messages Array<Message>
 * @param ignoreMessages boolean
 * @returns Array<Message>
 */
export function addEmptyWaitingMessage(
  messages: Message[],
  ignoreMessages = false,
): Message[] {
  const lastMessage = messages?.at(-1);
  if (lastMessage) {
    return lastMessage?.role === ConversationRole.USER && !ignoreMessages
      ? [
          ...messages,
          {
            content: '',
            role: ConversationRole.AGENT,
            user_id: lastMessage.user_id,
            from_user_id: lastMessage.to_user_id,
            to_user_id: lastMessage.from_user_id,
          },
        ]
      : messages;
  }
  return messages;
}

export const removeHashFromString = (text: string) => {
  return text.replace('#', '');
};

export const getTaskIdFromMessage = (message: Message): string | undefined => {
  if (isMessageWithCreationTaskId(message)) {
    return message?.payload?.task_id;
  }
  if (message.message_type === MessageType.TASK_CREATED) {
    return message?.task_id;
  }
};

const isAdvisorMessageType = (messageType?: MessageType) => {
  return (
    messageType === MessageType.CONVERSATION ||
    messageType === MessageType.TASK_CREATED
  );
};

/**
 * prepareMessagesToRender() add showDate and showSkillDivider flags to messages
 * for render them in the flat design
 * @param messages Array<Message>
 * @returns Array<Message>
 */
export const prepareMessagesToRender = (messages: Message[]) => {
  const updatedMessages = messages.map((message, index, array) => {
    const previousMessage = array[index - 1];
    const nextMessage = array[index + 1];

    const showDate =
      index === 0 ||
      new Date(message.timestamp || '').toLocaleDateString() !==
        new Date(previousMessage.timestamp || '').toLocaleDateString();

    const showSkillDivider =
      index > 0 &&
      message.role === ConversationRole.USER &&
      !!nextMessage?.message_type &&
      !(
        isAdvisorMessageType(nextMessage.message_type) &&
        isAdvisorMessageType(previousMessage.message_type)
      );

    // anchor for auto-scrollig to specific task
    const anchorId =
      message.role === ConversationRole.USER && nextMessage
        ? getTaskIdFromMessage(nextMessage)
        : '';

    return {
      ...message,
      showDate,
      showSkillDivider,
      anchorId,
    };
  });

  return updatedMessages;
};

export const getSkillFromMessage = (messageType: MessageType): MessageSkill => {
  switch (messageType) {
    case MessageType.SCHEDULER_TASK_CREATION_CARD:
      return MessageSkill.SCHEDULER;
    case MessageType.RESEARCH_TASK_CREATION_CARD:
      return MessageSkill.RESEARCHER;
    case MessageType.CODE_TASK_CREATION_CARD:
      return MessageSkill.CODER;

    default:
      return MessageSkill.ADVISOR;
  }
};

export const getSkillFromTask = (taskSkill: TaskSkill): MessageSkill => {
  switch (taskSkill) {
    case TaskSkill.SCHEDULING:
      return MessageSkill.SCHEDULER;
    case TaskSkill.RESEARCH:
      return MessageSkill.RESEARCHER;
    case TaskSkill.CODING:
      return MessageSkill.CODER;

    default:
      return MessageSkill.ADVISOR;
  }
};

export const groupMessagesByDate = (
  messages: Message[],
): GroupedMessagesByDate => {
  const groupedMessages: GroupedMessagesByDate = {};

  messages.forEach((message) => {
    const formattedDate = dayjs(message?.timestamp).format('MMMM D, YYYY');

    groupedMessages[formattedDate] = groupedMessages[formattedDate] || [];
    groupedMessages[formattedDate].push(message);
  });

  return groupedMessages;
};

export const handleScrollToMessageByTask = (taskId: string) => {
  const focusTaskElement = document.getElementById(taskId);

  if (focusTaskElement) {
    focusTaskElement?.scrollIntoView({ behavior: 'smooth' });
  }
};

export const getReadableTaskSkillName = (skill?: TaskSkill) => {
  switch (skill) {
    case TaskSkill.RESEARCH:
      return 'Researcher';
    case TaskSkill.SCHEDULING:
      return 'Scheduler';
    case TaskSkill.CODING:
      return 'Coder';
    case TaskSkill.CHITCHAT:
      return 'Advisor';
    default:
      return '';
  }
};

export const prepareExternalModelsReferences = (
  references: Record<string, AiGenerationData>,
): ExternalModelReference[] => {
  return Object.entries(references)
    .filter(([_, { model }]) => model && externalModelsSet[model])
    .map(([key, { content, model }]) => {
      return {
        modelKey: key,
        content: content || '',
        icon: model ? externalModelsSet[model].icon_name : null,
        title: model ? externalModelsSet[model].display_name : '',
        url: model || '',
      };
    });
};

export const getExternalModelData = (model?: string) => {
  if (!model || !externalModelsSet[model]) {
    return null;
  }

  return externalModelsSet[model];
};
