import { CircleNotch } from '@phosphor-icons/react';
import classNames from 'classnames';

const SVG_SIZE = 24;

type SpinnerProps = {
  inline?: boolean;
  visibility?: boolean;
};

export const Spinner = ({ inline = false, visibility = true }: SpinnerProps) => {
  return (
    <div className={classNames('nj-spinner', { inline, visibility })}>
      <CircleNotch size={SVG_SIZE} />
      {!inline && <span>Loading...</span>}
    </div>
  );
};
