import { Navigate, Route, Routes } from 'react-router-dom';
import { IS_SIGN_UP_REDIRECT } from 'src/utils';
import React, { Suspense } from 'react';
import { Loading } from 'src/components/Loading';
// import { CompleteAccountSetupPage } from 'src/pages/AuthPages/CompleteAccountSetupPage';

export const PublicContent = () => {
  const isOnboardingRedirect = localStorage.getItem(IS_SIGN_UP_REDIRECT);
  const isUserPassedPassPage = true;

  const LoginPage = React.lazy(() => import('src/pages/AuthPages/LoginPage'));
  const LoginWithEmailPage = React.lazy(() => import('src/pages/AuthPages/LoginWithEmailPage'));
  const SignupPage = React.lazy(() => import('src/pages/AuthPages/SignupPage'));
  const ForgotPasswordPage = React.lazy(() => import('src/pages/AuthPages/ForgotPasswordPage'));
  const ResetPasswordPage = React.lazy(() => import('src/pages/AuthPages/ResetPasswordPage'));
  const VerificationFailedPage = React.lazy(() => import('src/pages/AuthPages/VerificationFailedPage'));
  const VerifiedPage = React.lazy(() => import('src/pages/AuthPages/VerifiedPage'));
  const PasswordResetFailedPage = React.lazy(() => import('src/pages/AuthPages/PasswordResetFailedPage'));
  const SignupWithEmailPage = React.lazy(() => import('src/pages/AuthPages/SignupWithEmailPage'));
  const ErrorLoginPage = React.lazy(() => import('src/pages/AuthPages/ErrorLoginPage'));
  const TemporaryPasswordScreen = React.lazy(() => import('src/pages/AuthPages/components/TemporaryPasswordScreen'));
  const ManageTasksChatPage = React.lazy(() => import('src/pages/ManageTasksChatPage'));


  return (
    <Suspense fallback={<Loading />}>
    <Routes>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/login-with-email" element={<LoginWithEmailPage />}></Route>
      <Route path="/sign-up" element={<SignupPage />}></Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
      <Route path="/reset-password" element={<ResetPasswordPage />}></Route>
      <Route
        path="/verification-failed"
        element={<VerificationFailedPage />}
      ></Route>
      <Route path="/verified" element={<VerifiedPage />}></Route>
      <Route
        path="/password-reset-failed"
        element={<PasswordResetFailedPage />}
      ></Route>
      <Route
        path="/sign-up-with-email"
        element={<SignupWithEmailPage />}
      ></Route>
      {/*todo uncomment with future implementation*/}
      {/*<Route*/}
      {/*  path="/complete-account-setup"*/}
      {/*  element={<CompleteAccountSetupPage />}*/}
      {/*></Route>*/}
      <Route path="/login-error" element={<ErrorLoginPage />}></Route>
      <Route
        path="/"
        element={
          isUserPassedPassPage ? (
            <ManageTasksChatPage />
          ) : (
            <TemporaryPasswordScreen />
          )
        }
      ></Route>
      <Route
        path="*"
        element={
          <Navigate to={isOnboardingRedirect ? '/sign-up' : '/'} replace />
        }
      />
    </Routes>
    </Suspense>
  );
};
