import ReactDOM, { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { Loading } from 'src/components/Loading';
import { DebugContextProvider } from 'src/contexts/DebugContext';
import { AvatarModeContextProvider } from 'src/contexts/AvatarModeContext';
import { ForwardRefContextProvider } from 'src/contexts/ForwardRefContext';
import { SessionContextProvider } from 'src/contexts/SessionContext';
import { AudioContextProvider } from 'src/contexts/AudioContext';
import { ThreadInputBoxContextProvider } from 'src/contexts/ThreadInputBoxContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store, { persistor } from 'src/store';
import { AuthContextProvider } from 'src/contexts/AuthContext';
import { Amplify } from 'aws-amplify';
import { initializeGTM } from './utils/analytics';
import App from 'src/components/App';
import { FacebookPixel } from 'src/components/FacebookPixel';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-responsive-modal/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css';
import 'src/styles/index.scss';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
      userPoolClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
      loginWith: {
        oauth: {
          domain: `${process.env.REACT_APP_COGNITO_OATH_DOMAIN}`,
          scopes: [
            'profile',
            'email',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [`${process.env.REACT_APP_NINJA_UI_URL}`],
          redirectSignOut: [`${process.env.REACT_APP_NINJA_UI_URL}`],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

// Google Tag Manager
initializeGTM();
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement as HTMLElement);

const NinjaApp = () => (
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loading />}>
        <AuthContextProvider>
          <ForwardRefContextProvider>
            <DebugContextProvider>
              <AudioContextProvider>
                <AvatarModeContextProvider>
                  <SessionContextProvider>
                    <ThreadInputBoxContextProvider>
                      <GoogleOAuthProvider clientId={googleClientId}>
                        <HelmetProvider>
                          <FacebookPixel />
                          <App />
                        </HelmetProvider>
                      </GoogleOAuthProvider>
                    </ThreadInputBoxContextProvider>
                  </SessionContextProvider>
                </AvatarModeContextProvider>
              </AudioContextProvider>
            </DebugContextProvider>
          </ForwardRefContextProvider>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, <NinjaApp />);
} else {
  root.render(<NinjaApp />);
}
