import { useMemo, useContext, useCallback } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  setSelectedTaskDetail,
  useAppDispatch,
  useAppSelector,
  setNotification,
  clearNotification,
  sessionState,
  setAppUser,
  setOnboardingHintStep,
  setCurrentConversationId,
  setCurrentTaskId,
  setAvatarQueue,
  clearAvatarQueue,
  fetchTaskById,
  setIsAccessModalShown,
  avatarState,
  setChatMode,
  setFirstUserQuery,
  setChatModeSidePanel,
  setIsNavigationBarExpanded,
  setIsSettingsPanelExpanded,
  setIsUserSettingsBarExpanded,
  setChatsPageSize,
  interfaceControlState,
} from 'src/store';
import {
  ChatMode,
  CameFrom,
  Notification,
  ChatModeSidePanel,
  AppUser,
  ChatsFilter,
  AvatarQueue,
} from 'src/types';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserTier } from 'src/types/models/UserTier';
import { updateRenewEarlyStatus } from 'src/store/slices/sessionSlice';

export const useSession = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { chatsFilter, setChatsFilter } = useContext(SessionContext);

  const {
    agent,
    appUser,
    selectedTaskDetail,
    notification,
    isSocketConnected,
    onboardingHintsStep,
    currentConversationId,
    currentTaskId,
    isAccessModalShown,
    isFirstSchedulerTask,
    isFirstUserQuery,
  } = useAppSelector(sessionState);
  const {
    chatModeSidePanel,
    isNavigationBarExpanded,
    isSettingsPanelExpanded,
    isUserSettingsBarExpanded,
    chatsPageSize,
  } = useAppSelector(interfaceControlState);

  const { chatMode, avatarQueue } = useAppSelector(avatarState);

  const dispatch = useAppDispatch();

  const updateSelectedTaskDetail = (id: string, cameFrom?: CameFrom) => {
    dispatch(setSelectedTaskDetail({ selectedTaskId: id, cameFrom }));
  };

  const updateChatMode = (mode: ChatMode) => {
    dispatch(setChatMode(mode));

    if (pathname !== '/' && mode !== ChatMode.CALENDAR) {
      navigate('/');
    }
  };

  const updateChatsPageSize = (size: number) => {
    dispatch(setChatsPageSize(size));
  };

  const updateChatModeSidePanel = (panel: ChatModeSidePanel) => {
    dispatch(setChatModeSidePanel(panel));
  };

  const removeNotification = () => {
    dispatch(clearNotification());
  };

  const sendNotification = (notification: Notification) => {
    dispatch(setNotification(notification));
  };

  // TODO(olha): move to useTheme
  const toggleNavigationBarExpanded = (value?: boolean) => {
    if (value !== undefined) {
      dispatch(setIsNavigationBarExpanded(value));
    } else {
      dispatch(setIsNavigationBarExpanded(!isNavigationBarExpanded));
    }
  };

  // TODO(olha): move to useTheme
  const toggleUserSettingsBarExpanded = (value?: boolean) => {
    dispatch(setIsUserSettingsBarExpanded(value ?? !isUserSettingsBarExpanded));
  };

  // TODO(olha): move to useTheme
  const toggleSettingsPanelExpanded = (value?: boolean) => {
    if (value !== undefined) {
      dispatch(setIsSettingsPanelExpanded(value));
    } else {
      dispatch(setIsSettingsPanelExpanded(!isSettingsPanelExpanded));
    }
  };

  const changeOnboardingHintStep = (value: number) => {
    dispatch(setOnboardingHintStep(value));
  };

  const updateAppUser = (user: AppUser) => {
    dispatch(setAppUser(user));
  };

  const updateAvatarQueue = (value?: AvatarQueue) => {
    if (!value) {
      dispatch(clearAvatarQueue());
    } else {
      dispatch(setAvatarQueue(value));
    }
  };

  const setRenewEarlyPending = () => {
    dispatch(
      updateRenewEarlyStatus({
        renew_early_pending_subscription: {
          subscription_id: '',
          period_start: '',
          period_end: '',
        },
      }),
    );
  };

  const isTaskItemById = useMemo(() => {
    if (selectedTaskDetail) {
      const { selectedTaskId } = selectedTaskDetail;
      return !!selectedTaskId && selectedTaskId !== 'default';
    }
    return false;
    // eslint-disable-next-line
  }, [selectedTaskDetail?.selectedTaskId]);

  const updateCurrentTaskId = (taskId: string) => {
    dispatch(setCurrentTaskId(taskId));
    dispatch(
      fetchTaskById({
        userId: appUser.user_id,
        taskId,
      }),
    );
  };

  const removeCurrentTaskId = () => {
    dispatch(setCurrentTaskId());
  };

  const updateCurrentConversationId = (conversationId?: string) => {
    dispatch(setCurrentConversationId(conversationId));
  };

  const updateChatsFilter = (filter?: ChatsFilter) => {
    setChatsFilter(filter);
  };

  const updateIsAccessModalShown = (value: boolean) => {
    dispatch(setIsAccessModalShown(value));
  };

  const updateFirstUserQuery = useCallback(() => {
    dispatch(setFirstUserQuery());
  }, [dispatch]);

  return {
    notification,
    sendNotification,
    removeNotification,
    selectedTaskDetail,
    updateSelectedTaskDetail,
    agent,
    chatMode,
    updateChatMode,
    chatModeSidePanel,
    updateChatModeSidePanel,
    appUser,
    updateAppUser,
    isTaskItemById,
    isNavigationBarExpanded,
    isUserSettingsBarExpanded,
    isSettingsPanelExpanded,
    toggleNavigationBarExpanded,
    toggleUserSettingsBarExpanded,
    toggleSettingsPanelExpanded,
    isSocketConnected,
    onboardingHintsStep,
    changeOnboardingHintStep,
    currentConversationId,
    currentTaskId,
    updateCurrentTaskId,
    removeCurrentTaskId,
    updateCurrentConversationId,
    chatsFilter,
    updateChatsFilter,
    chatsPageSize,
    updateChatsPageSize,
    avatarQueue,
    updateAvatarQueue,
    isAccessModalShown,
    isFirstSchedulerTask,
    updateIsAccessModalShown,
    isFirstUserQuery,
    updateFirstUserQuery,
    isOpenTier: appUser?.tier_id === UserTier.OPEN,
    isFreeTier: appUser?.tier_id === UserTier.FREE,
    isProTier: appUser?.tier_id === UserTier.PAID,
    isTestTier: appUser?.tier_id === UserTier.TESTING,
    setRenewEarlyPending,
  };
};
