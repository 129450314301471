import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { tasksBaseUrl } from '../constants';
import {
  ConversationsByPageRequest,
  isConversation,
  isConversationList,
  Conversation,
  UpdateConversationHashStateRequest,
  UserConversationId,
  SortOrder,
} from 'src/types';
import { customFetchWithRetry } from './fetchRetry';
import { getErrorMessage } from 'src/utils';
import { get_access_token } from 'src/utils';

/**
 * fetchNextTopConversations() fetches next top conversations page with infinite scroll.
 * GET /v2/users/<user_id>/top_conversations?user_id=<user_id>&sort_order=<order>&limit=<limit>&page_token=xxx&include_archived=false
 */
export const fetchNextTopConversations = createAsyncThunk(
  'conversations/fetchNextTopConversations',
  async ({
    userId,
    limit = 50,
    sortOrder = SortOrder.ASC,
    pageToken = '',
    includeArchived = false,
    reload = false,
  }: ConversationsByPageRequest) => {
    const token = await get_access_token()
    const URL = `${tasksBaseUrl}/users/${userId}/top_conversations?sort_order=${sortOrder}&limit=${limit}&include_archived=${includeArchived}&page_token=${pageToken}`;

    const request = new Request(URL, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();
        const { page_token = '', data = [] } = json;
        if (isConversationList(data)) {
          return {
            pageToken: page_token,
            conversations: data,
            reload,
          };
        } else {
          throw new Error(
            `Fetched tasks list is not in the correct type format: ${response}.`,
          );
        }
      } else {
        throw new Error(`GET ${URL} failed to fetch response from the server.`);
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchConversationById() fetches a conversation by id.
 */
export const fetchConversationById = createAsyncThunk(
  'conversations/conversationById',
  async ({userId, conversationId}: UserConversationId, { rejectWithValue }) => {
    const URL = `${tasksBaseUrl}/users/${userId}/conversations/${conversationId}?show_tasks=true&show_messages=true`;

    try {
      const data = await customFetchWithRetry(URL);
      if (isConversation(data)) {
        return data;
      } else {
        throw new Error(`Fetched conversation is not of the supported type`);
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchUpdateConversationById() updates conversation with tasks api.
 * Attn: only updates hash and state of the conversation, if more
 * needs to be updated, contact BE to extend this portion of Task API.
 * PUT - /v2/conversations/${conversationId}
 */
export const fetchUpdateConversationById = createAsyncThunk(
  'conversations/updateConversationById',
  async (update: UpdateConversationHashStateRequest) => {
    const token = await get_access_token()
    const URL = `${tasksBaseUrl}/users/${update.user_id}/conversations/${update.conversation_id}`;
    const request = new Request(URL, {
      method: 'PATCH',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
      body: JSON.stringify(update),
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const data = await response.json();
        if (isConversation(data)) {
          return data as Conversation;
        } else {
          throw new Error(
            `Updated conversation is not of the supported type format: ${response}.`,
          );
        }
      } else {
        throw new Error(
          `PATCH ${URL} failed to fetch updated response from the server.`,
        );
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchArchivedAll() fetches everything as archived data.
 * GET /v2/users/<user_id>/archive_all
 */
export const fetchArchivedAll = createAsyncThunk(
  'conversations/fetchArchivedAll',
  async (userId: string) => {
    const token = await get_access_token()
    const URL = `${tasksBaseUrl}/users/${userId}/archive_all`;
    const request = new Request(URL, {
      method: 'post',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();
        // ultimately we don't need any data returned
        // after everything got archived
        return json;
      } else {
        throw new Error(
          `POST ${URL} failed to fetch response from the server.`,
        );
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);
