export const feedbackBaseUrl = process.env.REACT_APP_NINJA_FEEDBACK_API_URL;
export const tasksBaseUrl = process.env.REACT_APP_NINJA_API_V2_URL;
export const guestUsersBaseUrl = `${process.env.REACT_APP_NINJA_API_V2_URL}/users/guest`;
export const convEngineBaseUrl = process.env.REACT_APP_CONV_ENDPOINT;
export const contactsBaseUrl = process.env.REACT_APP_CONTACTS_ENDPOINT;
export const tokenBaseUrl = process.env.REACT_APP_NINJA_TOKEN_API_URL;
export const jsonBaseUrl = process.env.REACT_APP_S3_BUCKET;
export const combusBaseUrl = process.env.REACT_APP_COMBUS_ENDPOINT;
export const combusWSBaseUrl = process.env.REACT_APP_COMBUS_WS_ENDPOINT;
export const metahumanBaseUrl = process.env.REACT_APP_META_HUMAN_BASE_URL;
export const matchmakerBaseUrl = process.env.REACT_APP_AVATAR_MATCHMAKER_SERVER;
export const walletBaseUrl = process.env.REACT_APP_NINJA_WALLET_API_URL;
