import { useContext, useCallback } from 'react';
import { sanitize } from 'dompurify';
import DebugContext from 'src/contexts/DebugContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import AudioContext from 'src/contexts/AudioContext';
import { useSession, useFetchUserInput, useUserLocation } from 'src/hooks';
import {
  ConversationRole,
  MessageChannel,
  ChatMode,
  Message,
  Tag,
  AvatarAnimations,
  LocationSource,
} from 'src/types';
import { animateMetahuman } from 'src/utils';
import log from 'src/utils/logger';
import { DEFAULT_CHAT_ID } from 'src/constants';

export const useSubmitUserInput = () => {
  const { debugMode } = useContext(DebugContext);
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { threadMessageListAnchorRef } = useContext(ForwardRefContext);
  const { setMetaHumanTalking } = useContext(AudioContext);

  const {
    agent,
    chatMode,
    appUser,
    currentConversationId,
    isFirstUserQuery,
    updateFirstUserQuery,
  } = useSession();
  const { fetchAiData } = useFetchUserInput();
  const { setLocationInterval } = useUserLocation();

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  const handleUpdateUserLocation = useCallback(() => {
    if (!isFirstUserQuery && appUser.location?.source !== LocationSource.GPS) {
      setLocationInterval();
      updateFirstUserQuery();
    }
  }, [isFirstUserQuery, appUser, updateFirstUserQuery, setLocationInterval]);

  const onSubmitUserInput = useCallback(
    async (userInput: string, params?: Partial<Message>) => {
      // TODO(olha): move here trimInput call
      if (!userInput) {
        return;
      }

      handleUpdateUserLocation();

      if (isAvatarMode) {
        animateMetahuman(appUser.user_id, AvatarAnimations.THINKING, debugMode);
      }

      const newMessage = {
        user_id: appUser.user_id,
        from_user_id: appUser.user_id,
        to_user_id: agent.user_id,
        channel: MessageChannel.WEB_APP,
        role: ConversationRole.USER,
        content: sanitize(userInput),
        is_read: false,
        tag: Tag.CONVERSATION,
        timestamp: new Date().toISOString(),
        conversation_id: currentConversationId || DEFAULT_CHAT_ID,
        persona: appUser.persona,
        ...(params ? params : {}),
      } as Message;

      if (debugMode) {
        log.debug('handleSubmit >', sanitize(userInput), newMessage);
      }

      setThreadInputBoxValue('');

      await fetchAiData(newMessage);

      if (isAvatarMode) {
        // TODO(olha): it's a workaround until we have "beginSpeech" event from the Avatar game
        setMetaHumanTalking(true);
      }

      threadMessageListAnchorRef?.current?.onScrollToBottomMessageList();
    },
    [
      appUser,
      agent.user_id,
      currentConversationId,
      debugMode,
      isAvatarMode,
      threadMessageListAnchorRef,
      setMetaHumanTalking,
      handleUpdateUserLocation,
      fetchAiData,
      setThreadInputBoxValue,
    ],
  );

  return { onSubmitUserInput };
};
