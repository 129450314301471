import { useState, createContext } from 'react';
import {
  Message,
  TasksListSortByStatus,
  TasksListSortByTime,
  ChatsFilter,
  ModalGalleryType,
  ModalGalleryList,
} from 'src/types';

export type BubbleActionPanelProps = {
  conversationId: string;
  taskId: string;
  message: Message;
};

export type SessionContextType = {
  currentScrollingTaskId: string | null;
  shouldScrollToBottom: boolean;
  isOpenBubbleActionPanel: boolean;
  isOpenTaskListControlsPanel: boolean;
  bubbleActionPanelProps: BubbleActionPanelProps | null;
  sortedTasksByStatus: TasksListSortByStatus;
  sortedTasksByTime: TasksListSortByTime;
  isVisibleTaskViewModal: boolean;
  isVisibleModalGallery: boolean;
  isVisibleUpgradeModal: boolean;
  chatsFilter?: ChatsFilter;
  isShowAvatarSelect: boolean;
  setIsShowAvatarSelect: (value: boolean) => void;
  onOpenBubbleActionPanel: (props: BubbleActionPanelProps) => void;
  onCloseBubbleActionPanel: () => void;
  onOpenTaskListControlsPanel: () => void;
  onCloseTaskListControlsPanel: () => void;
  setCurrentScrollingTaskId: (value: string | null) => void;
  onToggleScrollToBottom: (value?: boolean) => void;
  setSortedTasksByStatus: (value: TasksListSortByStatus) => void;
  setSortedTasksByTime: (value: TasksListSortByTime) => void;
  onToggleVisibleTaskViewModal: (value?: boolean) => void;
  onToggleVisibleUpgradeModal: (value?: boolean) => void;
  setChatsFilter: (chatsFilter?: ChatsFilter) => void;
  isOpenThemeSelector: boolean;
  onChangeIsOpenTheme: (value: boolean) => void;
  isVisibleUpdatePlanModal: boolean;
  onChangeIsVisibleUpdatePlanModal: (value: boolean) => void;
  onChangeModalGalleryData: (
    isShow: boolean,
    modalType: ModalGalleryType | null,
    data: ModalGalleryList,
    defaultSelectedIndex: number,
  ) => void;
  currentModalGalleryType: ModalGalleryType | null;
  currentListOfGalleryItems: ModalGalleryList;
  defaultSelectedGalleryIndex: number;
};

type SessionContextProviderProps = {
  children: React.ReactNode;
};

const SessionContext = createContext<SessionContextType>({
  bubbleActionPanelProps: null,
  currentScrollingTaskId: null,
  shouldScrollToBottom: false,
  isOpenBubbleActionPanel: false,
  isOpenTaskListControlsPanel: false,
  sortedTasksByStatus: TasksListSortByStatus.ALL,
  sortedTasksByTime: TasksListSortByTime.DEFAULT,
  isVisibleTaskViewModal: false,
  isVisibleModalGallery: false,
  isVisibleUpgradeModal: false,
  chatsFilter: ChatsFilter.ALL,
  isShowAvatarSelect: false,
  setIsShowAvatarSelect: () => undefined,
  onOpenBubbleActionPanel: () => undefined,
  onCloseBubbleActionPanel: () => undefined,
  onOpenTaskListControlsPanel: () => undefined,
  onCloseTaskListControlsPanel: () => undefined,
  setCurrentScrollingTaskId: () => undefined,
  onToggleScrollToBottom: () => undefined,
  setSortedTasksByStatus: () => undefined,
  setSortedTasksByTime: () => undefined,
  onToggleVisibleTaskViewModal: () => undefined,
  onToggleVisibleUpgradeModal: () => undefined,
  setChatsFilter: () => undefined,
  isOpenThemeSelector: false,
  onChangeIsOpenTheme: () => undefined,
  isVisibleUpdatePlanModal: false,
  onChangeIsVisibleUpdatePlanModal: () => undefined,
  onChangeModalGalleryData: () => undefined,
  currentModalGalleryType: null,
  currentListOfGalleryItems: [],
  defaultSelectedGalleryIndex: 0,
});

const SessionContextProvider = ({ children }: SessionContextProviderProps) => {
  const [isVisibleBubbleActionPanel, setVisibleBubbleActionPanel] =
    useState<boolean>(false);
  const [bubbleActionPanelProps, setBubbleActionPanelProps] =
    useState<BubbleActionPanelProps | null>(null);
  const [isVisibleThemeSelector, setIsOpenTheme] = useState<boolean>(false);
  const [isShowAvatarSelect, setIsShowAvatarSelect] = useState<boolean>(false);
  const [isVisibleUpdatePlanModal, onChangeIsVisibleUpdatePlanModal] =
    useState<boolean>(false);
  const [defaultSelectedGalleryIndex, setDefaultSelectedGalleryIndex] =
    useState(0);

  const [isVisibleModalGallery, setIsVisibleModalGallery] =
    useState<boolean>(false);
  const [currentModalGalleryType, setCurrentModalGalleryType] =
    useState<ModalGalleryType | null>(null);
  const [currentListOfGalleryItems, setCurrentListOfGalleryItems] =
    useState<ModalGalleryList>([]);

  const handleChangeModalGallery = (
    isShowModal: boolean,
    modalContentType: ModalGalleryType | null,
    listOfItems: ModalGalleryList,
    defaultSelectedGalleryIndex: number,
  ) => {
    setCurrentListOfGalleryItems(listOfItems);
    setDefaultSelectedGalleryIndex(defaultSelectedGalleryIndex);
    setCurrentModalGalleryType(modalContentType);
    setIsVisibleModalGallery(isShowModal);
  };

  const handleOpenBubbleActionPanel = (props: BubbleActionPanelProps) => {
    setVisibleBubbleActionPanel(true);
    setBubbleActionPanelProps(props);
  };

  const handleCloseBubbleActionPanel = () => {
    setVisibleBubbleActionPanel(false);
    setBubbleActionPanelProps(null);
  };

  const [isVisibleTaskListControlsPanel, setVisibleTaskListControlsPanel] =
    useState<boolean>(false);

  const handleOpenTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(true);
  };

  const handleCloseTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(false);
  };

  const [currentScrollingTaskId, setCurrentScrollingTaskId] = useState<
    string | null
  >(null);

  const [shouldScrollToBottom, setShouldScrollToBottom] =
    useState<boolean>(false);

  const onToggleScrollToBottom = (value?: boolean) => {
    setShouldScrollToBottom(value ?? !shouldScrollToBottom);
  };

  const [sortedTasksByTime, setSortedTasksByTime] =
    useState<TasksListSortByTime>(TasksListSortByTime.DEFAULT);
  const [sortedTasksByStatus, setSortedTasksByStatus] =
    useState<TasksListSortByStatus>(TasksListSortByStatus.ALL);

  const [isVisibleTaskViewModal, serVisibleTaskViewModal] =
    useState<boolean>(false);

  const onToggleVisibleTaskViewModal = (value?: boolean) => {
    serVisibleTaskViewModal(value ?? !isVisibleTaskViewModal);
  };

  const [isVisibleUpgradeModal, serVisibleUpgradeModal] =
    useState<boolean>(false);

  const onToggleVisibleUpgradeModal = (value?: boolean) => {
    serVisibleUpgradeModal(value ?? !isVisibleUpgradeModal);
  };

  const [chatsFilter, setChatsFilter] = useState<ChatsFilter>(ChatsFilter.ALL);

  const onChangeChatsFilter = (chatsFilter?: ChatsFilter) => {
    setChatsFilter(chatsFilter ?? ChatsFilter.ALL);
  };

  return (
    <SessionContext.Provider
      value={{
        bubbleActionPanelProps,
        currentScrollingTaskId,
        shouldScrollToBottom,
        isOpenBubbleActionPanel: isVisibleBubbleActionPanel,
        isOpenTaskListControlsPanel: isVisibleTaskListControlsPanel,
        sortedTasksByStatus,
        sortedTasksByTime,
        isVisibleTaskViewModal,
        isVisibleModalGallery,
        isVisibleUpgradeModal,
        chatsFilter,
        isShowAvatarSelect,
        setIsShowAvatarSelect,
        onOpenBubbleActionPanel: handleOpenBubbleActionPanel,
        onCloseBubbleActionPanel: handleCloseBubbleActionPanel,
        onOpenTaskListControlsPanel: handleOpenTaskListControlsPanel,
        onCloseTaskListControlsPanel: handleCloseTaskListControlsPanel,
        setCurrentScrollingTaskId,
        onToggleScrollToBottom,
        setSortedTasksByStatus,
        setSortedTasksByTime,
        onToggleVisibleTaskViewModal,
        onToggleVisibleUpgradeModal,
        setChatsFilter: onChangeChatsFilter,
        isOpenThemeSelector: isVisibleThemeSelector,
        onChangeIsOpenTheme: setIsOpenTheme,
        isVisibleUpdatePlanModal,
        onChangeIsVisibleUpdatePlanModal,
        onChangeModalGalleryData: handleChangeModalGallery,
        currentModalGalleryType,
        currentListOfGalleryItems,
        defaultSelectedGalleryIndex,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };
export default SessionContext;
