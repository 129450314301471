import { createApi } from '@reduxjs/toolkit/query/react';
import { baseWalletQuery } from './config';
import { PaymentsSubscriptionInfo } from 'src/types';
// import log from 'src/utils/logger';

export enum WalletTags {
  Token = 'Token',
  Costs = 'Costs',
  UserSubscriptionInfo = 'UserSubscriptionInfo',
  BudgetQuota = 'BudgetQuota',
  TasksQuota = 'TasksQuota',
}

export const walletApi = createApi({
  reducerPath: 'walletApi',
  tagTypes: [
    WalletTags.Token,
    WalletTags.Costs,
    WalletTags.UserSubscriptionInfo,
    WalletTags.BudgetQuota,
    WalletTags.TasksQuota,
  ],
  refetchOnMountOrArgChange: true, // till not implemented PDU
  baseQuery: baseWalletQuery,
  endpoints: (builder) => ({
    // TODO(olha): add types from fast api when it will be ready
    getStripePrices: builder.query<unknown, { user_id: string }>({
      query: ({ user_id }) => `/stripe/prices/user/${user_id}`,
      providesTags: () => [WalletTags.Token],
      keepUnusedDataFor: 60 * 60 * 10,
    }),
    // TODO: add types from fast api when it is ready
    getStripeCosts: builder.query<
      { charged_tasks: never[] },
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/activity/user/${user_id}`,
      providesTags: () => [WalletTags.Costs],
    }),
    // TODO: add types from fast api when it is ready
    getUserSubscriptionInfo: builder.query<
      PaymentsSubscriptionInfo,
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/subscription/user/${user_id}`,
      providesTags: () => [WalletTags.UserSubscriptionInfo],
    }),
    // TODO: add types from fast api when it is ready
    getUserBudgetQuotaInfo: builder.query<
      {
        amount: number;
        is_low_balance: boolean;
        quota_exceeded: boolean;
      },
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/budget-quota/user/${user_id}`,
      providesTags: () => [WalletTags.BudgetQuota],
    }),
    // TODO: add types from fast api when it is ready
    getUserTaskQuotaInfo: builder.query<
      {
        count: number;
      },
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/task-count-quota/user/${user_id}`,
      providesTags: () => [WalletTags.TasksQuota],
      keepUnusedDataFor: 60 * 60 * 10,
    }),
    // TODO(olha): add types from fast api when it will be ready
    upgradeSubscription: builder.mutation({
      query: ({ user_id, lookup_key }) => ({
        url: `stripe/checkout-session/user/${user_id}`,
        method: 'POST',
        params: { lookup_key },
        body: {
          success_url: process.env.REACT_APP_NINJA_UI_URL,
          cancel_url: process.env.REACT_APP_NINJA_UI_URL,
        },
      }),
    }),
    renewSubscription: builder.mutation({
      query: ({ user_id, lookup_key }) => ({
        url: `stripe/subscription/renew/early/user/${user_id}?lookup_key=${lookup_key}`,
        method: 'POST',
      }),
    }),
    // TODO(olha): add types from fast api when it will be ready
    getBillingPortalUrl: builder.query<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      { user_id: string; return_url?: string }
    >({
      query: ({ user_id, return_url }) => ({
        url: `/stripe/billing-portal/user/${user_id}`,
        params: {
          // TODO(olha): check why we have / in the UI_URL at the end
          return_url: `${process.env.REACT_APP_NINJA_UI_URL}${return_url}`,
        },
      }),
    }),
  }),
});

export const {
  useGetUserBudgetQuotaInfoQuery,
  useGetUserTaskQuotaInfoQuery,
  useGetUserSubscriptionInfoQuery,
  useGetStripeCostsQuery,
  useGetStripePricesQuery,
  useUpgradeSubscriptionMutation,
  useLazyGetBillingPortalUrlQuery,
  useRenewSubscriptionMutation,
  useLazyGetUserBudgetQuotaInfoQuery,
} = walletApi;
