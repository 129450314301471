import { createContext, useEffect, useState, useMemo } from 'react';
import { useSession, useUserData, useBreakpoint } from 'src/hooks';
import { ChatMode } from 'src/types';
import { CameraIndexId } from 'src/types/models/CameraIndexId';
import { useSessionTimer } from 'src/hooks';
import { AVATAR_SESSION_DURATION_DEFAULT } from 'src/constants';

export interface MicrophoneTriggerHandlers {
  stopRecording: () => void;
  onToggleAction: () => void;
}

export type AvatarModeContextType = {
  expandedMeta: boolean;
  showTranscript: boolean;
  showAvatarQueueModal: boolean;
  showAvatarQueueBanner: boolean;
  shouldShowAvatarIframe: boolean;
  shouldShowAvatarAsFloating: boolean;
  avatarIsBeingDragged: boolean;
  avatarTimeLeft: number;
  isAvatarSessionRunning: boolean;
  toggleExpandedMeta: (shouldExpand?: boolean) => void;
  toggleTranscript: (showCC: boolean) => void;
  toggleCameraAngle: (cameraIndex: number) => void;
  setShowAvatarQueueModal: (showModal: boolean) => void;
  setShowAvatarQueueBanner: (showBanner: boolean) => void;
  replaceAvatarQueueModalWithBanner: () => void;
  setShouldShowAvatarIframe: (showIframe: boolean) => void;
  setAvatarIsBeingDragged: (isBeingDragged: boolean) => void;
  clearShowAvatarQueue: () => void;
  startAvatarSession: (milliseconds: number) => void;
  stopAvatarSession: () => void;
};

type AvatarModeContextProviderProps = {
  children: React.ReactNode;
};

/**
 * AvatarModeContext manages toggle for all avatar components.
 */
const AvatarModeContext = createContext<AvatarModeContextType>({
  expandedMeta: false,
  showTranscript: true,
  showAvatarQueueModal: false,
  showAvatarQueueBanner: false,
  shouldShowAvatarIframe: true,
  shouldShowAvatarAsFloating: false,
  avatarIsBeingDragged: false,
  avatarTimeLeft: 0,
  isAvatarSessionRunning: false,
  toggleExpandedMeta: () => undefined,
  toggleTranscript: () => undefined,
  toggleCameraAngle: () => undefined,
  setShowAvatarQueueModal: () => undefined,
  setShowAvatarQueueBanner: () => undefined,
  replaceAvatarQueueModalWithBanner: () => undefined,
  setShouldShowAvatarIframe: () => undefined,
  setAvatarIsBeingDragged: () => undefined,
  clearShowAvatarQueue: () => undefined,
  startAvatarSession: () => undefined,
  stopAvatarSession: () => undefined,
});

const AvatarModeContextProvider = ({
  children,
}: AvatarModeContextProviderProps) => {
  const { isDesktopLAndUp, isDesktopXLAvatarAndUp } = useBreakpoint();
  const { updateUserData } = useUserData();
  const { chatMode, appUser, isSettingsPanelExpanded } = useSession();
  const [expandedMeta, setExpandedMeta] = useState<boolean>(false);
  const [showTranscript, setShowTranscript] = useState<boolean>(true);
  const [showAvatarQueueModal, setShowAvatarQueueModal] =
    useState<boolean>(false);
  const [showAvatarQueueBanner, setShowAvatarQueueBanner] =
    useState<boolean>(false);
  const [shouldShowAvatarIframe, setShouldShowAvatarIframe] =
    useState<boolean>(false);
  const [avatarIsBeingDragged, setAvatarIsBeingDragged] =
    useState<boolean>(false);

  const {
    remainingTime: avatarTimeLeft,
    startSession: startAvatarSession,
    stopSession: stopAvatarSession,
    isRunning: isAvatarSessionRunning,
  } = useSessionTimer(AVATAR_SESSION_DURATION_DEFAULT);

  // todo it is disconnected from data BE sends temporary to do some research, uncomment in a future
  // useEffect(() => {
  //   const value =
  //     appUser?.settings?.camera_angle_setting?.is_show_cc === undefined
  //       ? chatMode !== ChatMode.AVATAR
  //       : appUser?.settings?.camera_angle_setting?.is_show_cc;
  //   setShowTranscript(value);
  // }, [appUser, chatMode]);

  // todo it is temporary added to do some research, remove in a future
  useEffect(() => {
    if (chatMode === ChatMode.AVATAR && !showTranscript) {
      setShowTranscript(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMode]);

  const toggleExpandedMeta = (shouldExpand?: boolean) => {
    setExpandedMeta((state) =>
      shouldExpand !== undefined ? shouldExpand : !state,
    );
  };

  const toggleTranscript = (showCC: boolean) => {
    setShowTranscript(showCC);
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          camera_angle_setting: {
            ...appUser.settings?.camera_angle_setting,
            show_cc: showCC,
          },
        },
      },
      false,
    );
  };

  const toggleCameraAngle = (cameraIndex: number) => {
    const newCameraIndex = cameraIndex > 4 ? 0 : cameraIndex;
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          camera_angle_setting: {
            ...appUser.settings?.camera_angle_setting,
            camera_index: newCameraIndex.toString() as CameraIndexId,
          },
        },
      },
      false,
    );
  };

  const replaceAvatarQueueModalWithBanner = () => {
    setShowAvatarQueueModal(false);
    setShowAvatarQueueBanner(true);
  };

  const clearShowAvatarQueue = () => {
    setShowAvatarQueueModal(false);
    setShowAvatarQueueBanner(false);
    setShouldShowAvatarIframe(false);
  };

  const shouldShowAvatarAsFloating = useMemo(() => {
    // make avatar float when settings panel is expanded
    // on desktop size less than 1515px
    if (isSettingsPanelExpanded && !isDesktopXLAvatarAndUp) {
      return true;
    }
    return !isDesktopLAndUp;
  }, [isSettingsPanelExpanded, isDesktopXLAvatarAndUp, isDesktopLAndUp]);

  return (
    <AvatarModeContext.Provider
      value={{
        expandedMeta,
        showTranscript,
        showAvatarQueueModal,
        showAvatarQueueBanner,
        shouldShowAvatarIframe,
        shouldShowAvatarAsFloating,
        avatarIsBeingDragged,
        avatarTimeLeft,
        isAvatarSessionRunning,
        startAvatarSession,
        stopAvatarSession,
        toggleExpandedMeta,
        toggleTranscript,
        toggleCameraAngle,
        setShowAvatarQueueModal,
        setShowAvatarQueueBanner,
        replaceAvatarQueueModalWithBanner,
        setShouldShowAvatarIframe,
        setAvatarIsBeingDragged,
        clearShowAvatarQueue,
      }}
    >
      {children}
    </AvatarModeContext.Provider>
  );
};

export { AvatarModeContextProvider };
export default AvatarModeContext;
