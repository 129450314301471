/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { PRODUCTION_ENVIRONMENT } from 'src/constants';

// The implementation was based on the recommendations from this article:
//https://medium.com/@dilhancodes/mastering-facebook-pixel-integration-in-react-boost-engagement-and-conversion-rates-7518b928f63e
export const FacebookPixel: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION_ENVIRONMENT) {
      return;
    }

    (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return;
      n = f.fbq = function (...args: any[]) {
        n.callMethod ? n.callMethod(...args) : n.queue.push(args);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    );

    window.fbq('init', process.env.REACT_APP_FACEBOOK_PIXEL_ID || '');
    window.fbq('track', 'PageView');
  }, []);

  return null;
};
