import { useMemo, useEffect } from 'react';
import {
  sessionState,
  setIsDarkTheme,
  setIsTasksListExpanded,
  useAppDispatch,
  useAppSelector,
  interfaceControlState,
} from 'src/store';
import { useUserData } from 'src/hooks/useUserData';
import { UIColors } from 'src/constants';
import { useSession } from 'src/hooks/useSession';
import { useBreakpoint } from './useBreakpoint';
import { Appearance } from 'src/types/models/Appearance';

export const useTheme = () => {
  const { appUser, isOpenTier } = useSession();
  const dispatch = useAppDispatch();
  const { isDesktopAndUp } = useBreakpoint();
  const { isDarkTheme } = useAppSelector(sessionState);
  const { isTasksListExpanded } = useAppSelector(interfaceControlState);
  const { updateUserData } = useUserData();

  // On app load, determine left panel expanded/collapsed state
  useEffect(() => {
    if (isDesktopAndUp && isOpenTier) {
      dispatch(setIsTasksListExpanded(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTasksListExpanded = (value: boolean) => {
    dispatch(setIsTasksListExpanded(value));
  };

  const toggleTasksListExpanded = () => {
    dispatch(setIsTasksListExpanded(!isTasksListExpanded));
  };

  const updateIsDarkTheme = (isDark: boolean) => {
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          app_appearance: {
            appearance: isDark ? Appearance.DARK : Appearance.LIGHT,
          },
        },
      },
      false,
    );
    dispatch(setIsDarkTheme(isDark));
  };

  const skeletonColors = useMemo(() => {
    const colors = isDarkTheme ? UIColors.darkMode : UIColors.lightMode;
    return {
      baseColor: colors.SKELETON_BACKGROUND,
      highlightColor: colors.SKELETON_HIGHLIGHT,
    };
  }, [isDarkTheme]);

  return {
    isTasksListExpanded,
    isDarkTheme:
      appUser?.settings?.app_appearance?.appearance === Appearance.DARK ??
      isDarkTheme,
    setIsDarkTheme: updateIsDarkTheme,
    setIsTasksListExpanded: updateTasksListExpanded,
    toggleTasksListExpanded,
    skeletonColors,
  };
};
